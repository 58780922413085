import Cookies from 'js-cookie';
import { QueryConfig } from 'react-query';
import { setSessionCacheFromLocalStorage } from 'services/bff/bff.service';
import { getContextCookies, getCookie } from 'services/cookie/cookie.service';
import { getExperimentFromQueryParam } from 'services/getExpirementFromQuery';
import { getScreenFromUrlPath } from 'services/window/window.service';
import CONFIG from '../../generated-config';

export const SCREEN_PATH_TO_SCREEN = {
  pagamento: 'PAYMENT',
  carrinho: 'BASKET',
  obrigado: 'THANKS',
  endereco: 'ADDRESS',
  confirmacao: 'CONFIRMATION',
  'completar-cadastro': 'COMPLETE-SIGNUP',
  login: 'LOGIN',
} as const;

export const buildCreateSessionQueryParams = (): {
  screen: Screens['name'];
  createSessionPayload: Screens['createPayload'];
  queryConfig?: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean };
} => {
  const screen = SCREEN_PATH_TO_SCREEN[getScreenFromUrlPath()];

  const safeMeta = JSON.parse(localStorage.getItem('sessionMeta') ?? '{}');

  const currentMeta = safeMeta.bffVersion === 'v1' ? safeMeta : undefined;

  switch (screen) {
    case 'BASKET': {
      setSessionCacheFromLocalStorage(screen);
      return buildBasketCreateSessionQueryParams(currentMeta);
    }
    case 'PAYMENT': {
      return buildPaymentCreateSessionQueryParams(currentMeta);
    }
    case 'THANKS': {
      return buildThanksCreateSessionQueryParams(currentMeta);
    }
    default: {
      return buildPaymentCreateSessionQueryParams(currentMeta);
    }
  }
};

const buildPaymentCreateSessionQueryParams = (
  currentMeta: SessionMeta,
): {
  screen: PaymentScreen['name'];
  createSessionPayload: PaymentScreen['createPayload'];
  queryConfig?: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean };
} => {
  const queryConfig = {
    dontAllowEmptyComponents: true,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data: Session) => {
      Cookies.remove('orderId', { domain: CONFIG.cookiesDomain });
      return data;
    },
  };
  const createSessionPayload = buildPaymentCreationSessionPayload(currentMeta);

  return { screen: 'PAYMENT', createSessionPayload, queryConfig };
};

const buildBasketCreateSessionQueryParams = (
  currentMeta: SessionMeta,
): {
  screen: BasketScreen['name'];
  createSessionPayload: BasketScreen['createPayload'];
  queryConfig?: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean };
} => {
  const queryConfig: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean } = {
    dontAllowEmptyComponents: true,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: ({ meta }) => {
      Cookies.set(CONFIG.cookiesDict.cartId.cookie, meta.cartId ?? '', {
        domain: CONFIG.cookiesDomain,
      });
    },
  };
  const createSessionPayload = buildBasketCreationSessionPayload(currentMeta);

  return { screen: 'BASKET', createSessionPayload, queryConfig };
};

const buildThanksCreateSessionQueryParams = (
  currentMeta: SessionMeta,
): {
  screen: ThanksScreen['name'];
  createSessionPayload: ThanksScreen['createPayload'];
  queryConfig?: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean };
} => {
  const queryConfig = {
    dontAllowEmptyComponents: true,
    retry: false,
    refetchOnWindowFocus: false,
  };
  const createSessionPayload = buildThanksCreationSessionPayload(currentMeta);

  return { screen: 'THANKS', createSessionPayload, queryConfig };
};

export const buildPaymentCreationSessionPayload = (
  currentMeta: SessionMeta,
): PaymentScreen['createPayload'] => {
  const cartId = getCookie(CONFIG.cookiesDict.cartId);
  const customerId = getCookie(CONFIG.cookiesDict.customerId);
  const zipCode = getCookie(CONFIG.cookiesDict.persistentCep);
  const preferredFreightOptionId =
    (getCookie(CONFIG.cookiesDict.preferredFreightOptionId) as FreightOptionId) || undefined;
  const context = getContextCookies();
  const storeApiId = getCookie(CONFIG.cookiesDict.storeApiId);
  const mockedExperiments = getExperimentFromQueryParam();

  return {
    cartId,
    zipCode,
    preferredFreightOptionId,
    context: { ...context, customerId },
    screen: 'PAYMENT',
    currentMeta,
    storeApiId,
    forcedABTests: mockedExperiments,
  };
};

export const buildBasketCreationSessionPayload = (
  currentMeta: SessionMeta,
): BasketScreen['createPayload'] => {
  const cartId = getCookie(CONFIG.cookiesDict.cartId);
  const customerId = getCookie(CONFIG.cookiesDict.customerId);
  const zipCode = getCookie(CONFIG.cookiesDict.persistentCep);
  const preferredFreightOptionId =
    (getCookie(CONFIG.cookiesDict.preferredFreightOptionId) as FreightOptionId) || undefined;
  const context = getContextCookies();
  const mockedExperiments = getExperimentFromQueryParam();

  return {
    cartId,
    zipCode,
    preferredFreightOptionId,
    context: { ...context, customerId },
    screen: 'BASKET',
    currentMeta,
    forcedABTests: mockedExperiments,
  };
};

export const buildThanksCreationSessionPayload = (
  currentMeta: SessionMeta,
): ThanksScreen['createPayload'] => {
  const orderId = getCookie(CONFIG.cookiesDict.orderId);
  const customerId = getCookie(CONFIG.cookiesDict.customerId);
  const context = getContextCookies();

  const checkoutIdentityIdFromQueryParam = getCookie('checkoutIdentityId');
  const cartIdentityIdFromQueryParam = getCookie('cartIdentityId');
  const paymentOptionTypeFromQueryParam = getCookie('paymentOptionType') as PaymentMenuOptionId;
  const mockedExperiments = getExperimentFromQueryParam();

  const updatedCurrentMeta: ThanksSessionMeta = {
    ...currentMeta,
    paymentOptionType: Boolean(paymentOptionTypeFromQueryParam)
      ? paymentOptionTypeFromQueryParam
      : currentMeta?.paymentOptionType,
    cartIdentityId: Boolean(cartIdentityIdFromQueryParam)
      ? cartIdentityIdFromQueryParam
      : currentMeta?.cartIdentityId,
    checkoutIdentityId: Boolean(checkoutIdentityIdFromQueryParam)
      ? checkoutIdentityIdFromQueryParam
      : currentMeta?.checkoutIdentityId,
  };

  return {
    orderId,
    context: { ...context, customerId },
    screen: 'THANKS',
    currentMeta: updatedCurrentMeta,
    forcedABTests: mockedExperiments,
  };
};
