import { useEffect, useState } from 'react';
import { useIsFetching } from 'react-query';

type QuantityChangeListener = (quantity: number) => void;

type BffActionManager = {
  actionsQuantity: number;
  quantityChangedListener: QuantityChangeListener;
  addAction: () => void;
  removeAction: () => void;
  subscribe: (cb: QuantityChangeListener) => void;
};

export const BFF_ACTION_MANAGER: BffActionManager = {
  actionsQuantity: 0,
  quantityChangedListener: () => {},
  addAction: function () {
    this.actionsQuantity = this.actionsQuantity + 1;
    this.quantityChangedListener(this.actionsQuantity);
  },
  removeAction: function () {
    this.actionsQuantity = this.actionsQuantity - 1;
    this.quantityChangedListener(this.actionsQuantity);
  },
  subscribe: function (callback) {
    this.quantityChangedListener = callback;
  },
};

export const useGlobalFetching = (): { mutation: boolean; query: boolean; all: boolean } => {
  const fetchingQueriesNumber = useIsFetching();

  const [onGoingMutationsNumber, setOnGoingMutationsNumber] = useState(0);

  useEffect(() => {
    BFF_ACTION_MANAGER.subscribe(number => {
      setOnGoingMutationsNumber(number);
    });
  }, []);

  return {
    mutation: Boolean(onGoingMutationsNumber),
    query: Boolean(fetchingQueriesNumber),
    all: Boolean(onGoingMutationsNumber) || Boolean(fetchingQueriesNumber),
  };
};
