import React, { useEffect } from 'react';

type BackdropComponentProps = {
  className: string;
  onAnimationEnd?: () => void;
  closeModalHandler: () => void;
  enableBackDropClick?: boolean;
  enableCloseOnEsc?: boolean;
  enableClose?: boolean;
};

const BackdropComponent: React.FC<BackdropComponentProps> = ({
  className,
  closeModalHandler,
  onAnimationEnd,
  children,
  enableBackDropClick,
  enableCloseOnEsc,
  enableClose,
}) => {
  const downHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeModalHandler();
    }
  };

  useEffect(() => {
    if (enableCloseOnEsc || enableClose) {
      document.body.style.overflowY = 'hidden';
      window.addEventListener('keydown', downHandler);
      return () => {
        window.removeEventListener('keydown', downHandler);
        document.body.style.overflowY = '';
      };
    }
    // eslint-disable-next-line
  }, [enableCloseOnEsc]);

  return (
    <div
      className={`new-modal-backdrop ${className}`}
      onAnimationEnd={onAnimationEnd}
      onClick={() => enableBackDropClick && enableClose && closeModalHandler()}
    >
      {children}
    </div>
  );
};

export default BackdropComponent;
