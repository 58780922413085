import { EventData } from 'hooks/bff/useBffAction';
import { useStack } from 'hooks/useStack';
import React, { useContext, createContext, ReactChild, useCallback, useRef } from 'react';
import { dispatchMetricEvent } from '../services/metrics/metrics';
import { buildDefaultMetricContext } from 'services/metrics/metricContext';

type ModalState = {
  currentStatus: 'CLOSED' | 'CLOSING' | 'OPENING' | 'OPEN';
  options: ModalOptions;
  component?: ReactChild;
};

type ModalCloseOptions = {
  forceClose?: boolean;
};

type ModalContext = {
  state: ModalState;
  closeModal: (closeOptions?: ModalCloseOptions) => void;
  openModal: (component: ReactChild, options: ModalOptions) => void;
  onAnimationEnd?: () => void;
  updateModalOptions?: (options: ModalOptions) => void;
};

export type ModalOptions = {
  metricData?: EventData & { metricModalId: string };
  hideCloseIcon?: boolean;
  modalDataCy?: string;
  enableBackDropClick?: boolean;
  enableCloseOnEsc?: boolean;
  wrapperClassName?: string;
  onModalClose?: () => void;
  enableClose?: boolean;
  modalHeader?: {
    type: WarningType;
    message: ReactChild;
  };
  ignoreStack?: boolean;
};

type ModalProps = {
  children: ReactChild;
};

const DEFAULT_OPTIONS: ModalOptions = {
  metricData: { metricModalId: '' },
  enableBackDropClick: true,
  enableCloseOnEsc: true,
  wrapperClassName: '',
  onModalClose: () => {},
  enableClose: true,
  hideCloseIcon: false,
  ignoreStack: false,
};

const INITIAL_STATE: ModalState = {
  currentStatus: 'CLOSED',
  options: DEFAULT_OPTIONS,
};

export const ModalContext = createContext<ModalContext>({
  state: INITIAL_STATE,
  openModal: () => {},
  closeModal: () => {},
});
ModalContext.displayName = 'ModalContext';

export const ModalProvider: React.FC<ModalProps> = ({ children }) => {
  const modalStack = useStack<ModalState>();

  const openedModal = useRef<ModalState>(INITIAL_STATE);
  openedModal.current = modalStack.top ?? INITIAL_STATE;

  const openModal = useCallback(
    (component: ReactChild, options: ModalOptions) => {
      if (options.metricData && options?.metricData.metricModalId !== 'store-conflict-modal') {
        const { metricModalId, ...rest } = options.metricData;

        const metric: TagMetric<'modal_open', PaymentOpenModalEventPayload> = {
          id: 'modal_open',
          type: 'TAG',
          payload: {
            ...buildDefaultMetricContext('BASKET'),
            event: 'modal_open',
            eventCategory: 'modal-open',
            eventAction: metricModalId,
            eventLabel: `modal_open: ${metricModalId}`,
            ...rest,
          },
        };

        dispatchMetricEvent(metric);
      }
      document.body.style.overflow = 'hidden';
      modalStack.add({
        currentStatus: 'OPENING',
        component,
        options: { ...DEFAULT_OPTIONS, ...options },
      });
    },
    [modalStack],
  );

  const updateModalOptions = (options: ModalOptions) => {
    if (!openedModal.current) {
      return;
    }
    modalStack.updateTop({ ...openedModal.current, options: { ...DEFAULT_OPTIONS, ...options } });
  };

  const onOpenedHandler = () => {
    if (!openedModal.current) {
      return;
    }
    modalStack.updateTop({ ...openedModal.current, currentStatus: 'OPEN' });
  };

  const closeModal = ({ forceClose = false }: ModalCloseOptions = {}) => {
    if (!openedModal.current) {
      return;
    }
    document.body.style.overflow = 'unset';
    const currentStatus = forceClose ? 'CLOSED' : 'CLOSING';

    modalStack.updateTop({ ...openedModal.current, currentStatus });
  };

  const onClosedHandler = () => {
    if (!openedModal.current) {
      return;
    }
    if (openedModal.current.options.ignoreStack) {
      modalStack.clear();
    }
    modalStack.remove();
    if (openedModal.current.options.onModalClose) {
      openedModal.current.options.onModalClose();
    }
  };

  const onAnimationEnd = () => {
    if (!openedModal.current) {
      return;
    }
    switch (openedModal.current.currentStatus) {
      case 'OPENING':
        onOpenedHandler();
        break;
      case 'CLOSING':
        onClosedHandler();
        break;
      default:
        break;
    }
  };

  return (
    <ModalContext.Provider
      value={{
        state: openedModal.current,
        closeModal,
        openModal,
        onAnimationEnd,
        updateModalOptions,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const { closeModal, openModal, updateModalOptions } = useContext(ModalContext);
  return { closeModal, openModal, updateModalOptions };
};
