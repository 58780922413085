import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Optional from 'optional-js';
import ResizeObserver from 'resize-observer-polyfill';

const CLOSED = 'CLOSED';
const OPENED = 'OPENED';
type CLOSED = typeof CLOSED;
type OPENED = typeof OPENED;

type ToggleCollapsable = () => void;
type RefreshCollapsable = () => void;
type SetCollapsableState = (state: string) => void;

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export type WithCollapsableProps = {
  CollapsableContainer: React.ComponentType;
  toggleCollapsable: ToggleCollapsable;
  refreshCollapsable: RefreshCollapsable;
  setCollapsableState: SetCollapsableState;
  collapsableStatus: OPENED | CLOSED;
};

export function withCollapsable<T extends WithCollapsableProps = WithCollapsableProps>(
  WrappedComponent: React.ComponentType<T>,
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return class ComponentWithCollapsable extends React.Component<
    Optionalize<T, WithCollapsableProps>,
    { STATUS: OPENED | CLOSED; containerHeight: number; resizeObserver: ResizeObserver | null }
  > {
    public static displayName = `withCollapsable(${displayName})`;

    constructor(props: Optionalize<T, WithCollapsableProps>) {
      super(props);
      this.state = {
        STATUS: CLOSED,
        containerHeight: 0,
        resizeObserver: null,
      };
    }

    collapsedContainerRef = React.createRef<HTMLDivElement>();

    toggleCollapsable = () => {
      const containerHeight = Optional.ofNullable(this.collapsedContainerRef)
        .map(c => c.current)
        .map(c => c.clientHeight)
        .orElse(0);

      if (this.state.STATUS === CLOSED) {
        this.setState({ STATUS: OPENED, containerHeight });
      } else {
        this.setState({ STATUS: CLOSED, containerHeight: 0 });
      }
    };

    setCollapsableState = (STATUS: OPENED | CLOSED) => {
      const containerHeight = Optional.ofNullable(this.collapsedContainerRef)
        .map(c => c.current)
        .map(c => c.clientHeight)
        .orElse(0);

      if (STATUS === 'CLOSED') {
        this.setState({ STATUS: CLOSED, containerHeight: 0 });
      } else {
        this.setState({ STATUS: OPENED, containerHeight });
      }
    };

    refreshCollapsableOnResize = () => {
      const resizeObserver = new ResizeObserver(() => {
        const containerHeight = Optional.ofNullable(this.collapsedContainerRef)
          .map(c => c.current)
          .map(c => c.clientHeight)
          .orElse(0);

        if (this.state.STATUS === 'CLOSED') {
          this.setState({ containerHeight: 0 });
        } else {
          this.setState({ containerHeight });
        }
      });

      this.collapsedContainerRef?.current &&
        resizeObserver.observe(this.collapsedContainerRef.current);
      return resizeObserver;
    };

    Container: React.FunctionComponent<{ children: ReactElement }> = props => {
      return (
        <CollapsableContainer
          ref={this.collapsedContainerRef}
          containerHeight={this.state.containerHeight}
          STATUS={this.state.STATUS}
        >
          {props.children}
        </CollapsableContainer>
      );
    };

    public render() {
      const themeProps = {
        CollapsableContainer: this.Container,
        toggleCollapsable: this.toggleCollapsable,
        setCollapsableState: this.setCollapsableState,
        collapsableStatus: this.state.STATUS,
      };

      return <WrappedComponent {...themeProps} {...(this.props as T)} />;
    }

    public componentDidMount() {
      this.setState({ resizeObserver: this.refreshCollapsableOnResize() });
    }

    public componentWillUnmount() {
      if (this.collapsedContainerRef.current) {
        this.state.resizeObserver?.unobserve(this.collapsedContainerRef.current);
      }
    }
  };
}

type CollapsableContainer = React.ForwardRefExoticComponent<{
  children: ReactElement;
  ref: React.Ref<HTMLDivElement>;
  containerHeight: number;
  STATUS: OPENED | CLOSED;
}>;

const CollapsableContainer = React.forwardRef<
  HTMLDivElement,
  {
    children: ReactElement;
    containerHeight: number;
    STATUS: OPENED | CLOSED;
  }
>((props, ref) => {
  return (
    <Collapsable
      style={{ height: props.containerHeight }}
      className={`${props.STATUS === OPENED ? '--opened' : ''}`}
    >
      <div style={{ padding: '1px 0' }} ref={ref}>
        {props.children}
      </div>
    </Collapsable>
  );
});

const Collapsable = styled.div`
  transition: all 0.4s ease;
  height: 0;
  margin-top: 0px;
  overflow: hidden;
`;
