import styled from 'styled-components';
import bugIcon from '../../assets/icons/debug.png';
import useModal from '../../modal';
import DebugModal from '../Debug.container';
import { isDebugMode } from '../../services/config.service';

const DebugContainer = () => {
  const { openModal } = useModal();

  if (!isDebugMode()) return null;

  return (
    <DebugButton
      onClick={() =>
        openModal(<DebugModal />, {
          wrapperClassName: 'center-modal',
          metricData: { metricModalId: 'debug-modal' },
        })
      }
    >
      <DebugIcon src={bugIcon} />
    </DebugButton>
  );
};

const DebugButton = styled.button`
  background-color: ${({ theme }) => theme.brandColor};
  border-radius: 5px;
  padding: 10px;
  border: 0px;
  position: fixed;
  bottom: 60px;
  left: 0px;
  z-index: 999999;
  font-size: 1.2rem;
  margin: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.2);
  }
`;

const DebugIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export default DebugContainer;
