import { useQuery } from 'react-query';

const useBffComponent = <C extends BaseComponent, P>(
  screen: SessionScreen,
  rel: C['rel'],
  extractFn: (component?: C) => P,
): [P, { isFetching: boolean; refetch: () => Promise<Session | undefined> }] => {
  const { data: sessionMeta } = useQuery<SessionMeta, ['SESSION_META', SessionScreen]>(
    ['SESSION_META', screen],
    { enabled: false },
  );

  const { data: screenData, isFetching, refetch } = useQuery<
    Session,
    ['BFF_SCREEN', SessionScreen, SessionMeta | undefined]
  >(['BFF_SCREEN', screen, sessionMeta], {
    enabled: false,
  });

  const components = screenData?.components ?? [];
  const component = components.find((c): c is C => c.rel === rel);

  return [extractFn(component), { isFetching, refetch }];
};

export default useBffComponent;
