import { useStack } from 'hooks/useStack';
import React, { useContext, createContext, ReactChild, useCallback, useRef } from 'react';

type FloatNotificationState = {
  currentStatus: 'CLOSED' | 'CLOSING' | 'OPENING' | 'OPEN';
  options: FloatNotificationOptions;
  component?: ReactChild;
};

type FloatNotificationContext = {
  state: FloatNotificationState;
  closeFloatNotification: () => void;
  openFloatNotification: (component: ReactChild, options?: FloatNotificationOptions) => void;
  onAnimationEnd?: () => void;
  updateFloatNotificationOptions?: (options: FloatNotificationOptions) => void;
};

export type FloatNotificationOptions = {
  metricData?: MetricEvent & { metricFloatNotificationId: string };
  hideCloseIcon?: boolean;
  floatNotificationDataCy?: string;
  enableCloseOnEsc?: boolean;
  wrapperClassName?: string;
  onFloatNotificationClose?: () => void;
  enableClose?: boolean;
  timeout?: number;
  floatNotificationHeader?: {
    type: WarningType;
    message: ReactChild;
  };
  ignoreStack?: boolean;
  type: WarningType;
};

const DEFAULT_OPTIONS: FloatNotificationOptions = {
  enableCloseOnEsc: true,
  wrapperClassName: '',
  onFloatNotificationClose: () => {},
  enableClose: true,
  hideCloseIcon: false,
  ignoreStack: false,
  type: 'SUCCESS',
};

const INITIAL_STATE: FloatNotificationState = {
  currentStatus: 'CLOSED',
  options: DEFAULT_OPTIONS,
};

export const FloatNotificationContext = createContext<FloatNotificationContext>({
  state: INITIAL_STATE,
  openFloatNotification: () => {},
  closeFloatNotification: () => {},
});
FloatNotificationContext.displayName = 'FloatNotificationContext';

export const FloatNotificationProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const floatNotificationStack = useStack<FloatNotificationState>();
  const openedFloatNotification = useRef<FloatNotificationState>(INITIAL_STATE);
  openedFloatNotification.current = floatNotificationStack.top ?? INITIAL_STATE;

  const openFloatNotification = useCallback(
    (component: ReactChild, options?: FloatNotificationOptions) => {
      floatNotificationStack.add({
        currentStatus: 'OPENING',
        component,
        options: { ...DEFAULT_OPTIONS, ...options },
      });
    },
    [floatNotificationStack],
  );

  const updateFloatNotificationOptions = (options: FloatNotificationOptions) => {
    if (!openedFloatNotification.current) {
      return;
    }
    floatNotificationStack.updateTop({
      ...openedFloatNotification.current,
      options: { ...DEFAULT_OPTIONS, ...options },
    });
  };

  const onOpenedHandler = () => {
    if (!openedFloatNotification.current) {
      return;
    }
    floatNotificationStack.updateTop({ ...openedFloatNotification.current, currentStatus: 'OPEN' });
  };

  const closeFloatNotification = () => {
    if (!openedFloatNotification.current) {
      return;
    }
    floatNotificationStack.clear();
  };

  const onClosedHandler = () => {
    if (!openedFloatNotification.current) {
      return;
    }
    if (openedFloatNotification.current.options.ignoreStack) {
      floatNotificationStack.clear();
    }
    floatNotificationStack.remove();
    if (openedFloatNotification.current.options.onFloatNotificationClose) {
      openedFloatNotification.current.options.onFloatNotificationClose();
    }
  };

  const onAnimationEnd = () => {
    if (!openedFloatNotification.current) {
      return;
    }
    switch (openedFloatNotification.current.currentStatus) {
      case 'OPENING':
        onOpenedHandler();
        break;
      case 'CLOSING':
        onClosedHandler();
        break;
      default:
        break;
    }
  };

  return (
    <FloatNotificationContext.Provider
      value={{
        state: openedFloatNotification.current,
        closeFloatNotification,
        openFloatNotification,
        onAnimationEnd,
        updateFloatNotificationOptions,
      }}
    >
      {children}
    </FloatNotificationContext.Provider>
  );
};

export const useFloatNotification = () => {
  const { closeFloatNotification, openFloatNotification, updateFloatNotificationOptions } =
    useContext(FloatNotificationContext);
  return { closeFloatNotification, openFloatNotification, updateFloatNotificationOptions };
};
