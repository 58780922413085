import { useQuery, QueryStatus, QueryResult, QueryConfig } from 'react-query';
import { isBffResponseWithoutComponents, standAloneAction } from '../../services/bff/bff.service';
import { dispatchPageAction } from '../../services/metrics/datadog';
import { useDebug } from '../../debug/Debug.context';
import { metricsMiddleware } from 'services/metrics/middleware';

type UseBffStandAloneScreen = (
  screen: SessionScreen,
  action: StandaloneAction,
  queryConfig?: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean },
) => QueryResult<Session>;

export const useBffStandAloneScreen: UseBffStandAloneScreen = (
  screen,
  action,
  queryConfig = { enabled: true },
) => {
  const { data: sessionMeta } = useQuery<SessionMeta>(['SESSION_META', screen], {
    enabled: false,
  });

  const { addDebugEntryLog } = useDebug();

  const screenQueryResult = useQuery<Session, BffErrorResponse>(
    ['BFF_STAND_ALONE', action, sessionMeta],
    (_, screen, meta) => standAloneAction({ meta, action: action }),
    {
      ...queryConfig,
      enabled: sessionMeta && (queryConfig?.enabled ?? true),
      onSuccess: data => {
        dispatchPageAction(`${screen}:standalone:${action.type}`, {
          status: 'success',
          actionType: action.type,
          sessionMeta: JSON.stringify(sessionMeta),
        });

        addDebugEntryLog({
          ...data,
          action: action.type,
          actionPayload: action.payload,
          status: 'success',
          type: 'BFF_LOG',
        });

        if (typeof queryConfig?.onSuccess === 'function') {
          queryConfig.onSuccess(data);
        }
      },
      onError: async error => {
        dispatchPageAction(`${screen}:standalone:${action.type}`, {
          status: 'error',
          actionType: action.type,
          sessionMeta: JSON.stringify(sessionMeta),
          newSessionMeta: JSON.stringify(error?.response?.data?.meta ?? {}),
        });

        //@ts-ignore
        const failedSession = ((error?.response?.data ?? {}) as any) as FailedSession;
        addDebugEntryLog({
          ...failedSession,
          action: action.type,
          actionPayload: action.payload,
          status: 'error',
          type: 'BFF_LOG',
        });

        if (typeof queryConfig?.onError === 'function') {
          return await queryConfig.onError(error);
        }
      },
      onSettled: (response, ...args) => {
        metricsMiddleware(response);
        if (typeof queryConfig?.onSettled === 'function') {
          queryConfig.onSettled(response, ...args);
        }
      },
    },
  );

  if (
    queryConfig?.dontAllowEmptyComponents &&
    isBffResponseWithoutComponents(screenQueryResult.data)
  ) {
    screenQueryResult.status = QueryStatus.Error;
  }

  return screenQueryResult;
};

export default useBffStandAloneScreen;
