import React, { useContext, createContext, useReducer, useCallback } from 'react';
import { MultipleCardFormValues } from '../multipleCreditCard/multipleCreditCard.types';
import { SingleCardFormValues } from '../singleCreditCard/singleCreditCard.types';

type DefinedCreditCardContext = {
  saveCreditCard: (
    creditCardId: string,
    creditCard: SingleCardFormValues | MultipleCardFormValues,
  ) => void;
  saveNewCardFormValues: (creditCard: SingleCardFormValues | MultipleCardFormValues) => void;
  saveSelectedCreditCard: (id: string) => void;
  saveCardMode: (cardMode: CreditCardPaymentBehaviour) => void;
  getDefinedCreditCard: (id: string) => SingleCardFormValues | MultipleCardFormValues | void;
  getNewCardFormValues: () => SingleCardFormValues | MultipleCardFormValues | void;
  selectedCreditCardId?: string;
  cardMode?: CreditCardPaymentBehaviour;
};

export const DefinedCreditCardContext = createContext<DefinedCreditCardContext>({
  saveCreditCard: () => {},
  saveSelectedCreditCard: () => {},
  saveNewCardFormValues: () => {},
  getNewCardFormValues: () => {},
  saveCardMode: () => {},
  getDefinedCreditCard: () => {},
});

const SAVE_DEFINED_CREDIT_CARD = 'SAVE_DEFINED_CREDIT_CARD';
const SAVE_SELECTED_CREDIT_CARD = 'SAVE_SELECTED_CREDIT_CARD';
const SAVE_CARD_MODE = 'SAVE_CARD_MODE';

type DefinedCreditCardAction =
  | {
      type: typeof SAVE_DEFINED_CREDIT_CARD;
      payload: { cardId: string; formValues: SingleCardFormValues | MultipleCardFormValues };
    }
  | { type: typeof SAVE_SELECTED_CREDIT_CARD; payload: string }
  | { type: typeof SAVE_CARD_MODE; payload: CreditCardPaymentBehaviour };

type DefinedCreditCardState = {
  definedCreditCards: { [key: string]: SingleCardFormValues | MultipleCardFormValues };
  selectedCreditCardId?: string;
  cardMode?: CreditCardPaymentBehaviour;
};

const INITIAL_STATE: DefinedCreditCardState = {
  definedCreditCards: {},
};

const reducer = (
  state: DefinedCreditCardState = INITIAL_STATE,
  action: DefinedCreditCardAction,
) => {
  switch (action.type) {
    case SAVE_DEFINED_CREDIT_CARD:
      return {
        ...state,
        definedCreditCards: {
          ...state.definedCreditCards,
          [action.payload.cardId]: action.payload.formValues,
        },
      };
    case SAVE_SELECTED_CREDIT_CARD:
      return {
        ...state,
        selectedCreditCardId: action.payload,
      };
    case SAVE_CARD_MODE:
      return {
        ...state,
        cardMode: action.payload,
      };
    default:
      return state;
  }
};

DefinedCreditCardContext.displayName = 'DefinedCreditCardContext';

export const DefinedCreditCardProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const getDefinedCreditCard = (id: string) => state.definedCreditCards[id];

  const saveDefinedCreditCard = useCallback(
    (creditCardId: string, formValues: SingleCardFormValues | MultipleCardFormValues) => {
      dispatch({
        type: SAVE_DEFINED_CREDIT_CARD,
        payload: { formValues: formValues, cardId: creditCardId },
      });
    },
    [],
  );

  const saveSelectedCreditCard = useCallback((id: string) => {
    dispatch({ type: SAVE_SELECTED_CREDIT_CARD, payload: id });
  }, []);

  const saveCardMode = useCallback((cardMode: CreditCardPaymentBehaviour) => {
    dispatch({ type: SAVE_CARD_MODE, payload: cardMode });
  }, []);

  const { cardMode, selectedCreditCardId } = state;

  return (
    <DefinedCreditCardContext.Provider
      value={{
        saveCreditCard: saveDefinedCreditCard,
        saveSelectedCreditCard,
        saveCardMode,
        getDefinedCreditCard,
        saveNewCardFormValues: formValues => saveDefinedCreditCard('NEW', formValues),
        getNewCardFormValues: () => getDefinedCreditCard('NEW'),
        selectedCreditCardId,
        cardMode,
      }}
    >
      {children}
    </DefinedCreditCardContext.Provider>
  );
};

export const useDefinedCreditCards = () => {
  const {
    saveCreditCard,
    saveSelectedCreditCard,
    saveCardMode,
    getDefinedCreditCard,
    selectedCreditCardId,
    saveNewCardFormValues,
    getNewCardFormValues,
    cardMode,
  } = useContext(DefinedCreditCardContext);
  return {
    saveCreditCard,
    saveSelectedCreditCard,
    saveNewCardFormValues,
    getNewCardFormValues,
    saveCardMode,
    getDefinedCreditCard,
    selectedCreditCardId,
    cardMode,
  };
};
