import React, { useContext, useReducer, createContext } from 'react';

const ADD_ENTRY_LOG = 'ADD_ENTRY_LOG';
const CHANGE_DEBUG_MODE = 'CHANGE_DEBUG_MODE';

type BaseDebugBffPayload = {
  debug?: {
    sentRequests?: MetaSentRequest[] | undefined;
    failedRequests?: MetaFailedRequest[] | undefined;
  };
  meta: Partial<SessionMeta>;
  components: BaseComponent[];
  action: ActionType | StandaloneActionType | 'CREATE_SESSION';
  actionPayload: { [K: string]: any };
  type: 'BFF_LOG';
};

type SuccessfulActionDebugPayload = BaseDebugBffPayload & {
  status: 'success';
};

type FailedActionDebugPayload = BaseDebugBffPayload & {
  status: 'error';
  actionError: { error: { message: string } };
};

type BaseRequestDebugPayload = {
  method: string;
  body: any;
  fullUrl: string;
  requestId: string;
  type: 'REQUEST_LOG';
};

export type SuccessfulRequestDebugPayload = BaseRequestDebugPayload & {
  status: 'success';
};

export type FailedRequestDebugPayload = BaseRequestDebugPayload & {
  status: 'error';
  error: { message: string; statusCode: number };
};

export type BffDebugPayload = SuccessfulActionDebugPayload | FailedActionDebugPayload;

export type RequestDebugPayload = SuccessfulRequestDebugPayload | FailedRequestDebugPayload;

export type DebugPayload = BffDebugPayload | RequestDebugPayload;

export type DebugState = {
  debugMode: DebugMode;
  debugPayloadList: DebugPayload[];
};

export type DebugMode = 'MODE_LIST' | 'CONFIG_LIST' | 'ACTION_HISTORY' | 'REQUEST_HISTORY';

export type DebugAction =
  | {
      type: typeof ADD_ENTRY_LOG;
      payload: DebugPayload;
    }
  | { type: typeof CHANGE_DEBUG_MODE; payload: DebugState['debugMode'] };

const INITIAL_STATE: DebugState = {
  debugMode: 'MODE_LIST',
  debugPayloadList: [],
};

const DebugContext = createContext<{
  state: DebugState;
  addDebugEntryLog: (debugPayload: DebugPayload) => void;
  changeDebugMode: (debugMode: DebugState['debugMode']) => void;
}>({
  state: { debugMode: 'MODE_LIST', debugPayloadList: [] },
  addDebugEntryLog: () => {},
  changeDebugMode: () => {},
});

const debugReducer = (state: DebugState, action: DebugAction) => {
  switch (action.type) {
    case ADD_ENTRY_LOG:
      return { ...state, debugPayloadList: [...state.debugPayloadList, action.payload] };

    case CHANGE_DEBUG_MODE:
      return { ...state, debugMode: action.payload };

    default:
      return state;
  }
};

export const DebugContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(debugReducer, INITIAL_STATE);

  const addDebugEntryLog = (debugPayload: DebugPayload) => {
    dispatch({
      type: 'ADD_ENTRY_LOG',
      payload: debugPayload,
    });
  };

  const changeDebugMode = (debugMode: DebugState['debugMode']) => {
    dispatch({
      type: 'CHANGE_DEBUG_MODE',
      payload: debugMode,
    });
  };

  return (
    <DebugContext.Provider value={{ state, addDebugEntryLog, changeDebugMode }}>
      {children}
    </DebugContext.Provider>
  );
};

export const useDebug = () => {
  const { state, addDebugEntryLog, changeDebugMode } = useContext(DebugContext);
  return {
    logs: state.debugPayloadList,
    debugMode: state.debugMode,
    changeDebugMode,
    addDebugEntryLog,
  };
};
