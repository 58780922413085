const AmeBrand: React.FC<{ fill?: string; className?: string }> = ({
  fill = '#666',
  className = '',
}) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g id="ame_brand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M17.1688636,4.96283524 C17.7110572,5.02033874 18.2254592,5.25419421 18.6217972,5.65023498 L21.3097845,8.33707194 C22.2300718,9.25699761 22.2300718,10.7476859 21.3098089,11.6675871 L13.6642395,19.3108559 C12.7450189,20.2297147 11.2542416,20.2297147 10.3349964,19.3108313 L2.68905893,11.6671943 C1.7703079,10.7473584 1.7703079,9.25720451 2.68948528,8.33706266 L5.37781407,5.64988458 C5.7745938,5.25379869 6.28942964,5.01998464 6.83222789,4.97206112 L6.83215332,4.96283524 L17.1688636,4.96283524 Z M11.9255539,10.0880447 L11.8514116,10.0832512 C11.8394071,10.0824988 11.8274072,10.0816549 11.8154132,10.0807196 C11.7934745,10.0791481 11.7711319,10.0767811 11.7488212,10.0740496 C11.2322035,10.0195068 10.7306049,9.79422087 10.3348587,9.3986823 L7.64745066,6.71152023 C7.4932023,6.55756932 7.29089594,6.47156067 7.07463596,6.46345659 C6.83611894,6.45457709 6.60773992,6.54157588 6.43789643,6.71112213 L3.75031003,9.39755808 C3.41655753,9.73166088 3.41655753,10.2729788 3.74995212,10.6067673 L11.3954724,18.2499869 C11.7289544,18.5833377 12.2703062,18.5833377 12.6037636,18.2500115 L20.2493328,10.6067429 C20.5835557,10.2726513 20.5835557,9.73203212 20.2493421,9.39794982 L17.5614496,6.71120755 C17.4046808,6.55455641 17.1981653,6.46842032 16.9797061,6.46309063 L16.9248644,6.46344764 L16.8711113,6.46710601 C16.6752577,6.48641593 16.4938462,6.57034492 16.3530301,6.7111221 L13.6642395,9.39876235 C13.2695988,9.79324777 12.7696133,10.0183712 12.2547211,10.0741264 C12.2302435,10.0767685 12.2055346,10.0793761 12.1807979,10.081538 C12.1708398,10.081724 12.1608401,10.0824269 12.1508372,10.0830662 C12.0757579,10.0893976 12.0006029,10.0907633 11.9255539,10.0880447 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default AmeBrand;
