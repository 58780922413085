import { dispatchEvent, dispatchMetricEvent } from 'services/metrics/metrics';

export const metricsMiddleware = (response?: Session) => {
  if (response && 'metrics' in response && Array.isArray(response.metrics)) {
    const metrics = response.metrics;
    metrics.forEach(dispatchMetricEvent);
  }

  // DEPRECADO
  if (response && 'components' in response && Array.isArray(response.components)) {
    const components = response.components;
    const metricsComponent = components.find(
      (c): c is PaymentMetricsComponent | ThanksMetricsComponent => c.rel === 'metrics',
    );

    const metrics__DEPRECATED = metricsComponent?.payload ?? [];
    metrics__DEPRECATED.forEach(dispatchEvent);
  }
};
