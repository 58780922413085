import React, { useContext, createContext, useState, useEffect } from 'react';
import { BFF_SCREENS, BFF_ACTIONS } from '../../services/bff/bff.service';
import { useBffComponent, useBffAction } from '../../hooks/bff';
import extractPaymentMenu from './paymentMenu.extractor';
import { queryCache } from 'react-query';
import { security } from 'services/security/antifraudTool';

type PaymentMenuContext = {
  selectedPaymentOptionId: PaymentMenuOptionId | 'NO_SELECTED_OPTION';
  setPaymentOptionId: (optionId: PaymentMenuOptionId) => void;
};

const PaymentMenuContext = createContext<PaymentMenuContext>({
  selectedPaymentOptionId: 'NO_SELECTED_OPTION',
  setPaymentOptionId: () => {},
});

export const PaymentContextProvider: React.FC = ({ children }) => {
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<
    PaymentMenuOptionId | 'NO_SELECTED_OPTION'
  >('NO_SELECTED_OPTION');

  const [{ selectedOptionId }] = useBffComponent(
    BFF_SCREENS.PAYMENT,
    'payment-menu',
    extractPaymentMenu,
  );

  const [changePaymentActionDispatcher] = useBffAction<ChangePaymentActionPayload>(
    BFF_SCREENS.PAYMENT,
    BFF_ACTIONS.CHANGE_PAYMENT_OPTION,
    {
      onMutate: mutateArgs => {
        const {
          payload: { paymentMenuOptionId },
          sessionMeta,
        } = mutateArgs;

        const paymentMenuSessionCache = queryCache.getQueryData<SuccessfulSession>([
          'BFF_SCREEN',
          BFF_SCREENS.PAYMENT,
          sessionMeta,
        ]);

        if (paymentMenuSessionCache) {
          const paymentMenuOptions =
            paymentMenuSessionCache.components.find(
              (c): c is PaymentMenuComponent => c.rel === 'payment-menu',
            )?.payload ?? [];

          const oldSelectedOption = paymentMenuOptions.find(
            (c): c is PaymentMenuOptionComponent => c.behaviour === 'SELECTED',
          );
          if (oldSelectedOption) oldSelectedOption.behaviour = 'VISIBLE';

          const newSelectedOption = paymentMenuOptions.find(
            (c): c is PaymentMenuOptionComponent => c.id === paymentMenuOptionId,
          );
          if (newSelectedOption) newSelectedOption.behaviour = 'SELECTED';
        }

        queryCache.setQueryData(
          ['BFF_SCREEN', BFF_SCREENS.PAYMENT, sessionMeta],
          paymentMenuSessionCache,
        );
      },
    },
  );

  useEffect(() => {
    selectedOptionId !== 'NO_SELECTED_OPTION' && setSelectedPaymentOptionId(selectedOptionId);
    // eslint-disable-next-line
  }, [selectedOptionId]);

  const setPaymentOptionId = (optionId: PaymentMenuOptionId) => {
    setSelectedPaymentOptionId(optionId);
    changePaymentActionDispatcher(
      { paymentMenuOptionId: optionId },
      { paymentMenuOptionId: optionId },
    );
    security.setPaymentOption();
  };

  return (
    <PaymentMenuContext.Provider
      value={{ selectedPaymentOptionId: selectedPaymentOptionId, setPaymentOptionId }}
    >
      {children}
    </PaymentMenuContext.Provider>
  );
};

export const usePaymentMenu = () => {
  const { setPaymentOptionId, selectedPaymentOptionId } = useContext(PaymentMenuContext);
  return { setPaymentOptionId, selectedPaymentOptionId };
};
