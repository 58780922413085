import { DebugPayload, useDebug } from 'debug/Debug.context';
import { useScreenRedirect } from 'hooks/useScreenRedirect.hook';
import { QueryConfig, useQuery } from 'react-query';
import {
  BFF_SCREENS,
  createSession,
  isBffResponseWithoutComponents,
  setSessionCache,
} from 'services/bff/bff.service';
import { metricsMiddleware } from 'services/metrics/middleware';
import { buildScreenCustomMetrics, dispatchPageAction } from 'services/metrics/datadog';
import { useHasSessionMeta } from './useHasSessionMeta';

export const useCreateBffSession = <S extends Screens>(
  screen: S['name'],
  createSessionPayload: S['createPayload'],
  queryConfig: QueryConfig<Session> & { dontAllowEmptyComponents?: boolean } = { enabled: true },
) => {
  const { addDebugEntryLog } = useDebug();
  const handleRedirect = useScreenRedirect();
  const hasSessionMeta = useHasSessionMeta(BFF_SCREENS[screen]);

  const navigation = performance.getEntriesByType('navigation')[0] ?? { type: '' };

  const createBffSessionMutationArray = useQuery<Session, BffErrorResponse>(
    ['CREATE_BFF_SESSION', screen],
    (_, __) => createSession(createSessionPayload),
    {
      ...queryConfig,
      enabled: !hasSessionMeta,
      onSuccess: data => {
        dipatchCreateSessionMetrics(
          data,
          addDebugEntryLog,
          createSessionPayload,
          navigation,
          screen,
        );

        setSessionCache(data);
        localStorage.setItem('sessionMeta', JSON.stringify({ ...data.meta, bffVersion: 'v1' }));

        handleRedirect({
          session: data,
        });

        if (queryConfig.dontAllowEmptyComponents && isBffResponseWithoutComponents(data)) {
          throw new Error('No components found on BFF response.');
        }

        if (queryConfig.onSuccess && typeof queryConfig.onSuccess === 'function') {
          return queryConfig.onSuccess(data);
        }

        return data;
      },
      onError: async error => {
        dispatchPageAction(`CREATE_SESSION`, {
          status: 'error',
          actionType: 'CREATE_SESSION',
          navigationType: navigation.entryType,
          newSessionMeta: JSON.stringify(error?.response?.data?.meta ?? {}),
        });

        //@ts-ignore
        const failedSession = ((error?.response?.data ?? {}) as any) as FailedSession;
        addDebugEntryLog({
          ...failedSession,
          action: 'CREATE_SESSION',
          actionPayload: createSessionPayload,
          status: 'error',
          type: 'BFF_LOG',
        });

        if (queryConfig.onError && typeof queryConfig.onError === 'function') {
          return queryConfig.onError(error);
        }
      },
      onSettled: (response, ...args) => {
        metricsMiddleware(response);
        if (typeof queryConfig?.onSettled === 'function') {
          queryConfig.onSettled(response, ...args);
        }
      },
    },
  );

  return { ...createBffSessionMutationArray, hasSessionMeta };
};

const dipatchCreateSessionMetrics = (
  data: Session,
  addDebugEntryLog: (debugPayload: DebugPayload) => void,
  createSessionPayload: Screens['createPayload'],
  navigation: PerformanceEntry,
  screen: Screens['name'],
) => {
  const experiments =
    data?.meta?.experiments?.reduce((acc, v) => ({ ...acc, [v.experimentId]: v.variant }), {}) ??
    {};

  dispatchPageAction(`${screen}:CREATE_SESSION`, {
    status: 'success',
    actionType: 'CREATE_SESSION',
    screen: data.screen,
    newSessionMeta: JSON.stringify(data.meta),
    ...buildScreenCustomMetrics(data),
    navigationType: navigation.entryType,
    paymentOptionType: data.meta?.paymentOptionType,
    ...experiments,
  });

  addDebugEntryLog({
    ...data,
    action: `CREATE_SESSION`,
    actionPayload: createSessionPayload,
    status: 'success',
    type: 'BFF_LOG',
  });
};
