import styled from 'styled-components';
import copyIcon from '../../assets/icons/copy.png';
import { useEffect, useRef } from 'react';
import Clipboard from 'clipboard';

const CopyIconComponent: React.FC<{ clipboardText: string }> = ({ clipboardText }) => {
  const ref = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (ref.current) {
      const clipboard = new Clipboard(ref.current);
      clipboard.on('success', () => {
        alert('Copiado para área de transferência');
      });
    }
  }, [ref]);

  return <CopyIcon src={copyIcon} data-clipboard-text={clipboardText} ref={ref} />;
};

export default CopyIconComponent;

const CopyIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
`;
