import CONFIG from '../../generated-config';
import { getCookie } from '../cookie/cookie.service';

type DeviceParams = {
  deviceCookie: string;
  deviceStorage: string;
};

export const isMobileDevice = (): boolean => {
  const b2wDeviceType = getCookie('b2wDeviceType');
  return b2wDeviceType !== 'desktop';
};

const getOsFromCookie = (deviceCookie: string): string => {
  if (deviceCookie) return 'MOBILE';
  return 'DESKTOP';
};

const getTypeFromCookie = (deviceCookie: string): string => {
  if (deviceCookie) {
    const decodedCookie = window.atob(deviceCookie);
    const parsedCookie = JSON.parse(decodedCookie);
    const deviceCookieType = parsedCookie ? parsedCookie.type : 'DESKTOP';

    return deviceCookieType;
  }
  return 'DESKTOP';
};

const getDeviceTypeFromCookies = ({ deviceStorage, deviceCookie }: DeviceParams): string => {
  const isApp = Boolean(deviceStorage);
  if (isApp) {
    return getOsFromCookie(deviceCookie);
  } else {
    return getTypeFromCookie(deviceCookie);
  }
};

export const getOrigin = () => {
  const utmSource = getCookie('utm_source');

  const deviceType = getDeviceTypeFromCookies({
    deviceCookie: getCookie('b2wDevice'),
    deviceStorage: utmSource,
  });

  if (utmSource === 'app-ios' || utmSource === 'app-android') {
    return CONFIG.brandUrl + ' Mobile';
  } else if (deviceType === 'DESKTOP') {
    return 'Site ' + CONFIG.brandUrl;
  } else {
    return 'Site Mobile ' + CONFIG.brandUrl;
  }
};

type MOBILE_OPERATING_SYSTEM = 'ANDROID' | 'IOS' | 'UNKNOWN';

export const getMobileOperatingSystem = (): MOBILE_OPERATING_SYSTEM => {
  var userAgent = navigator.userAgent || '';

  if (/android/i.test(userAgent)) {
    return 'ANDROID';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'IOS';
  }

  return 'UNKNOWN';
};

export const getDeviceTypeFromBrowser = (): DeviceType => {
  const isApp =
    window.location.search.includes('utm_medium=app') ||
    window.location.search.includes('app_medium=app');

  if (isApp) {
    return 'APP';
  }
  const userAgent = navigator.userAgent ?? '';

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);

  if (isMobile) {
    return 'MOBILE';
  }

  return 'DESKTOP';
};
