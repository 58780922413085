import { getContextCookies } from '../cookie/cookie.service';
import CONFIG from '../../generated-config';
import { EventData } from 'hooks/bff/useBffAction';
import { datadogRum } from '@datadog/browser-rum';

export const dispatchPageAction = (actionName: string, eventData: EventData = {}) => {
  const context = getContextCookies();

  datadogRum.addAction(`payment-desk:${actionName}`, {
    ...eventData,
    b2wSid: context.b2wSid ?? 'NO_B2W_SID_FOUND',
    b2wPid: context.b2wPid,
    brand: CONFIG.brand,
  });
};

export const buildScreenCustomMetrics = (session: Session): EventData => {
  switch (session.screen) {
    case 'PAYMENT': {
      const components = session.components ?? [];

      const creditCardComponent = components.find(
        (v): v is CreditCardPaymentComponentPCIFlow => v.rel === 'credit-card-payment-pci-flow',
      );
      const qtdSavedCards = creditCardComponent?.payload.savedCards.length ?? 0;

      const productListComponent = components.find(
        (v): v is ProductListComponent => v.rel === 'product-list',
      );

      const qtdProducts =
        productListComponent?.behaviour === 'VISIBLE'
          ? productListComponent.payload.products?.length
          : 0;

      return { qtdSavedCards, qtdProducts };
    }
    default: {
      return {};
    }
  }
};
