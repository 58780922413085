import styled from 'styled-components';
import { BffDebugPayload, DebugMode, RequestDebugPayload } from '../Debug.context';
import DebugItem from './DebugItem.component';
import { BrandButton } from '../../components/brand-button.component';

const DebugHistoryComponent: React.FC<{
  bffLogs: BffDebugPayload[];
  requestLogs: RequestDebugPayload[];
  debugMode: DebugMode;
  onBackClickHandler: () => void;
}> = ({ bffLogs, requestLogs, debugMode, onBackClickHandler }) => {
  return (
    <>
      <DebugModalTitle className="--ignore-case">Histórico de ações executadas</DebugModalTitle>
      <DebugActionListWrapper>
        {debugMode === 'ACTION_HISTORY'
          ? bffLogs.map((v, index) => (
              <DebugItem key={`debug-action-item__${index}`} logEntry={v} />
            ))
          : requestLogs.map((v, index) => (
              <DebugItem key={`debug-action-item__${index}`} logEntry={v} />
            ))}
      </DebugActionListWrapper>
      <ActionButtonsWrapper>
        <BrandButton buttonType="secondary" onClick={onBackClickHandler}>
          voltar
        </BrandButton>
      </ActionButtonsWrapper>
    </>
  );
};

const DebugModalTitle = styled.h3`
  text-align: center;
  padding-bottom: 20px;
  font-size: 24px;
`;

const DebugActionListWrapper = styled.div`
  margin-bottom: 40px;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DebugHistoryComponent;
