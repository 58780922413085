import axios from 'axios';
import CONFIG from '../../generated-config';
import { v4 } from 'uuid';
import { getContextCookies, getCookie } from 'services/cookie/cookie.service';
import { MetricEvent } from './metrics';
import { isMobileDevice } from 'services/device/device.service';

export const requestSaikaPageVisit = async (eventData: MetricEvent['saikaData'] = {}) => {
  const context = getContextCookies();
  try {
    await axios({
      method: 'POST',
      url: CONFIG.apis.saika.pageVisit,
      data: {
        brand: CONFIG.brand === 'prtl' ? 'aemp' : CONFIG.brand,
        data: {
          page: 'payment',
          type: 'payment',
          path: window.location.pathname,
          url: window.location.href,
          ...eventData,
        },
        id: v4(),
      },
      params: {
        c_b2wChave: context.b2wChave,
        c_b2wDeviceType: context.b2wDeviceType,
        c_gadget: isMobileDevice() ? 'MOBILESITE' : 'NAOMOBILE',
        c_b2wEPar: context.b2wEPar,
        c_b2wPid: context.b2wPid,
        c_b2wSid: context.b2wSid,
        c_b2wUid: context.b2wUid,
        c_customerId: getCookie(CONFIG.cookiesDict.customerId),
        c_region: getCookie(CONFIG.cookiesDict.b2wRegion),
      },
    });
  } catch (e) {
    console.error('Erro ao fazer o request para o Saika.');
  }
};

export const requestSaikaFreightVisualization = async (
  eventData: MetricEvent['saikaData'] = {},
) => {
  const context = getContextCookies();
  try {
    await axios({
      method: 'POST',
      url: CONFIG.apis.saika.freightVisualization,
      data: {
        brand: CONFIG.brand === 'prtl' ? 'aemp' : CONFIG.brand,
        data: {
          origin: 'payment',
        },
        references: {
          ...eventData,
        },
        id: v4(),
        source: 'FREIGHT-COLLECTOR',
      },
      params: {
        c_b2wChave: context.b2wChave,
        c_b2wDeviceType: context.b2wDeviceType,
        c_gadget: isMobileDevice() ? 'MOBILESITE' : 'NAOMOBILE',
        c_b2wEPar: context.b2wEPar,
        c_b2wPid: context.b2wPid,
        c_b2wSid: context.b2wSid,
        c_b2wUid: context.b2wUid,
        c_customerId: getCookie(CONFIG.cookiesDict.customerId),
        c_region: getCookie(CONFIG.cookiesDict.b2wRegion),
      },
    });
  } catch (e) {
    console.error('Erro ao fazer o request para o Saika.');
  }
};
