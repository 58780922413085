import {
  queryCache,
  QueryFunction,
  ReactQueryMutationsConfig,
  useMutation,
  useQuery,
} from 'react-query';
import { BFF_ACTION_MANAGER } from './useGlobalFetching';
import {
  sessionActionRequest,
  BFF_ACTIONS,
  BFF_STAND_ALONE_ACTIONS,
  setSessionCache,
} from '../../services/bff/bff.service';
import { MutationResult } from 'react-query';

import { useScreenRedirect } from '../useScreenRedirect.hook';
import { buildScreenCustomMetrics, dispatchPageAction } from '../../services/metrics/datadog';
import { useDebug } from '../../debug/Debug.context';
import { metricsMiddleware } from 'services/metrics/middleware';

export type EventData = { [key: string]: string | number | boolean | undefined };

export type DoActionFunction<P, T = Session> = (
  payload: P,
  eventData?: EventData,
) => Promise<T | undefined>;

const refetchAmeData = (actionType: string) => {
  const ameRefetchingActionTypes: string[] = [
    BFF_ACTIONS.CHANGE_FREIGHT,
    BFF_ACTIONS.SELECT_STORE,
    BFF_ACTIONS.APPLY_GIFT_VOUCHER,
    BFF_ACTIONS.APPLY_TICKET,
    BFF_ACTIONS.CHANGE_ADDRESS,
    BFF_ACTIONS.REMOVE_COUPON,
    BFF_ACTIONS.REMOVE_VOUCHER,
    BFF_ACTIONS.CHANGE_RECIPIENT,
    BFF_ACTIONS.UPDATE_ADDRESS_DOCUMENT,
  ];
  if (ameRefetchingActionTypes.includes(actionType)) {
    queryCache.refetchQueries([
      'BFF_STAND_ALONE',
      { type: BFF_STAND_ALONE_ACTIONS.GET_WALLET_PAYMENT },
    ]);
  }
};

export const useBffAction = <P extends Action['payload']>(
  screen: SessionScreen,
  ACTION_TYPE: Action['type'],
  mutateConfig?: ReactQueryMutationsConfig<
    Session,
    BffErrorResponse,
    { payload: P; sessionMeta: SessionMeta }
  >,
): [DoActionFunction<P>, MutationResult<Session>] => {
  const handleRedirect = useScreenRedirect(ACTION_TYPE);
  const { addDebugEntryLog } = useDebug();

  const { data: sessionMeta } = useQuery<SessionMeta>(
    ['SESSION_META', screen],
    (() => {}) as QueryFunction<SessionMeta>,
    {
      initialData: () => queryCache.getQueryData(['SESSION_META', screen]),
      enabled: false,
    },
  );

  const [mutationFn, ...mutationRest] = useMutation(
    (requestPayload: { payload: P; sessionMeta: SessionMeta; eventData?: EventData }) => {
      BFF_ACTION_MANAGER.addAction();
      return sessionActionRequest({
        action: {
          type: ACTION_TYPE,
          payload: requestPayload.payload,
        },
        meta: sessionMeta as SessionMeta,
      });
    },
    {
      ...mutateConfig,
      onMutate: variables => {
        dispatchPageAction(`${screen}:${ACTION_TYPE}`, {
          status: 'requested',
          actionType: ACTION_TYPE,
          sessionMeta: JSON.stringify(variables.sessionMeta),
          ...variables.eventData,
        });
        if (typeof mutateConfig?.onMutate === 'function') {
          return mutateConfig.onMutate(variables);
        }
      },
      onSuccess: (data, variables) => {
        const experiments =
          data?.meta?.experiments?.reduce(
            (acc, v) => ({ ...acc, [v.experimentId]: v.variant }),
            {},
          ) ?? {};

        dispatchPageAction(`${screen}:${ACTION_TYPE}`, {
          status: 'success',
          actionType: ACTION_TYPE,
          screen: data.screen,
          sessionMeta: JSON.stringify(variables.sessionMeta),
          newSessionMeta: JSON.stringify(data.meta),
          paymentOptionType: data.meta?.paymentOptionType,
          ...buildScreenCustomMetrics(data),
          ...variables.eventData,
          ...experiments,
        });

        addDebugEntryLog({
          ...data,
          action: ACTION_TYPE,
          actionPayload: variables.payload,
          status: 'success',
          type: 'BFF_LOG',
        });

        setSessionCache(data, variables.sessionMeta);
        localStorage.setItem('sessionMeta', JSON.stringify({ ...data.meta, bffVersion: 'v1' }));

        refetchAmeData(ACTION_TYPE);
        handleRedirect({ userScreen: screen, session: data });

        if (typeof mutateConfig?.onSuccess === 'function') {
          mutateConfig.onSuccess(data, variables);
        }
      },
      onError: async (error: BffErrorResponse, variables, rollback) => {
        dispatchPageAction(`${screen}:${ACTION_TYPE}`, {
          status: 'error',
          actionType: ACTION_TYPE,
          sessionMeta: JSON.stringify(variables.sessionMeta),
          newSessionMeta: JSON.stringify(error?.response?.data?.meta ?? {}),
          ...variables.eventData,
        });
        //@ts-ignore
        const failedSession = (error?.response?.data ?? {}) as any as FailedSession;
        addDebugEntryLog({
          ...failedSession,
          action: ACTION_TYPE,
          actionPayload: variables.payload,
          status: 'error',
          type: 'BFF_LOG',
        });

        if (typeof mutateConfig?.onError === 'function') {
          return await mutateConfig.onError(error, variables, rollback);
        } else if (typeof rollback === 'function') {
          return await rollback();
        }
      },
      onSettled: (response, ...args) => {
        metricsMiddleware(response);
        BFF_ACTION_MANAGER.removeAction();
        if (typeof mutateConfig?.onSettled === 'function') {
          mutateConfig.onSettled(response, ...args);
        }
      },
    },
  );

  return [
    (payload, eventData) => {
      if (sessionMeta) {
        return mutationFn({ payload, sessionMeta, eventData });
      } else {
        console.error(
          `SESSION_META not found for screen<${screen}> with actionType<${ACTION_TYPE}>`,
        );

        return Promise.reject();
      }
    },
    ...mutationRest,
  ];
};
