import { DebugPayload } from '../Debug.context';
import styled from 'styled-components';
import { withCollapsable, WithCollapsableProps } from '../../components/collapsable.component';
import { ReactComponent as ArrowDown } from '../../assets/icons/icon-arrow-down.svg';
import DebugItemInfo from './DebugItemInfo.component';

const DebugItem: React.FC<{ logEntry: DebugPayload } & WithCollapsableProps> = ({
  CollapsableContainer,
  collapsableStatus,
  toggleCollapsable,
  logEntry,
}) => {
  return (
    <ActionWrapper>
      <DebugModalTitle
        onClick={toggleCollapsable}
        collapsableStatus={collapsableStatus}
        hasError={logEntry.status === 'error'}
      >
        {logEntry.type === 'BFF_LOG' ? logEntry.action : logEntry.requestId}
      </DebugModalTitle>
      <CollapsableContainer>
        {logEntry.status === 'error' && (
          <ActionErrorMessage className="--ignore-case">
            Mensagem de erro:{' '}
            {logEntry.type === 'BFF_LOG'
              ? logEntry?.actionError?.error?.message ?? ''
              : logEntry.error.message}
          </ActionErrorMessage>
        )}
        <DebugItemInfo logEntry={logEntry} />
      </CollapsableContainer>
    </ActionWrapper>
  );
};

const DebugModalTitle: React.FC<
  {
    collapsableStatus: 'OPENED' | 'CLOSED';
    hasError: boolean;
  } & React.HTMLAttributes<HTMLHeadingElement>
> = ({ children, collapsableStatus, hasError, ...props }) => {
  return (
    <CollapsableMainTitleWrapper collapsableStatus={collapsableStatus} {...props}>
      <Title className="--ignore-case" hasError={hasError}>
        {children}
      </Title>
      <ArrowDown className="collapsableArrow" />
    </CollapsableMainTitleWrapper>
  );
};

const CollapsableMainTitleWrapper = styled.div<{ collapsableStatus: 'OPENED' | 'CLOSED' }>`
  position: relative;
  cursor: pointer;
  > .collapsableArrow {
    position: absolute;
    top: calc(50% - 10px);
    right: 15px;
    width: 10px;
    height: auto;
    transition: transform 0.5s ease;
    transform: ${props => (props.collapsableStatus === 'OPENED' ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

const Title = styled.h2<{ hasError: boolean }>`
  display: flex;
  color: ${({ hasError }) => (hasError ? 'red' : '#00cd00')};
  font-size: 16px;
  font-weight: 700;
  padding: 20px 15px;
  margin: -20px -15px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 20px;
  padding: 15px;
  border-bottom: 1px solid #e3e3e3;
`;

const ActionErrorMessage = styled.p`
  color: red;
  margin: 0px 10px 20px 0px;
`;

export default withCollapsable(DebugItem);
