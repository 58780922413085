import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ReactComponent as iconWarning } from '../assets/icons/icon-alert-warning.svg';
import { ReactComponent as iconError } from '../assets/icons/icon-alert-error.svg';
import { ReactComponent as iconInfo } from '../assets/icons/icon-alert-info.svg';
import { ReactComponent as iconSuccess } from '../assets/icons/icon-alert-success.svg';

import iconClose from '../assets/icons/icon-close.svg';
import Optional from 'optional-js';

type NotificationProps = {
  type: WarningType;
  messageId?: WarningCode;
  disableClose?: boolean;
  className?: string;
};

const NotificationComponent: React.FC<NotificationProps> = ({
  type,
  children,
  messageId,
  disableClose,
  className,
}) => {
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    setIsClosed(false);
  }, [messageId]);

  if (isClosed) {
    return null;
  }

  return (
    <Notification
      className={className}
      type={type}
      isClosed={isClosed}
      data-cy={`notification-message__${messageId}`}
    >
      <NotificationFigure type={type}>{notificationTypeToIcon(type)}</NotificationFigure>
      {children}

      {!disableClose && type !== 'ERROR' && (
        <NotificationClose
          data-cy={messageId ? messageId : null}
          onClick={e => {
            e.preventDefault();
            setIsClosed(true);
          }}
        >
          <NotificationCloseIcon src={iconClose} />
        </NotificationClose>
      )}
    </Notification>
  );
};

const NOTIFICATION_TYPE_TO_ICON: {
  [K in WarningType]: React.FC<React.SVGProps<SVGSVGElement>>;
} = {
  WARNING: iconWarning,
  ERROR: iconError,
  INFO: iconInfo,
  SUCCESS: iconSuccess,
};

const notificationTypeToIcon = (type: WarningType) => {
  const Icon = Optional.ofNullable(type)
    .map(v => NOTIFICATION_TYPE_TO_ICON[v])
    .orElse(NOTIFICATION_TYPE_TO_ICON.INFO);

  return <Icon />;
};

const Notification = styled.div<{ type: WarningType; isClosed: boolean }>`
  background-color: ${props =>
    props.theme.notification.backgroundColor[props.type] ||
    props.theme.notification.backgroundColor.INFO};
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  color: #333333;
  font-size: 14px;
  position: relative;
  ${(props: { isClosed: boolean }) => (props.isClosed ? 'display: none' : '')}
  border-radius: 8px;
  > p {
    color: #333333;
    font-size: 14px;
    margin-right: auto;
    line-height: 1.5;
  }
`;

const NotificationFigure = styled.figure<{ type: WarningType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  width: 30px;
  margin-right: 0.5rem;
  > svg {
    max-width: 20px;
    max-height: 20px;
    fill: ${props =>
      props.theme.notification.figureBackgroundColor[props.type] ||
      props.theme.notification.figureBackgroundColor.INFO};
  }
`;

const NotificationClose = styled.button`
  margin: 0 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  border: none;
  background: transparent;
`;

const NotificationCloseIcon = styled.img`
  width: 10px;
`;

export default NotificationComponent;
