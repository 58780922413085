import { requestSaikaFreightVisualization, requestSaikaPageVisit } from './saika';

type Navigation = {
  host: string;
  location: {
    pathname: string;
    search: string;
  };
  referrer: string;
  url: string;
  page: string;
  urlhost: string;
  title: string;
};

export type MetricEvent = PaymentMetricEvent | ThanksMetricEvent;
export type MetricsId = MetricEvent['id'];

const isDevelopment = process.env.NODE_ENV === 'development';
const shouldLog = isDevelopment || window.location.search.includes('log=true');

export const dispatchEvent = (baseEvent: MetricEvent) => {
  try {
    if (baseEvent.id === 'page:load') {
      requestSaikaPageVisit(baseEvent.saikaData);
    } else if (baseEvent.id === 'freight:calculate') {
      requestSaikaFreightVisualization(baseEvent.saikaData);
    }

    const dataLayer = window?.dataLayer ?? [];

    const googleMetric = {
      title: window.document.title,
      url: window.location.href,
      ...baseEvent.payload,
    };

    if (!isDevelopment) {
      dataLayer.push(googleMetric);
    }
  } catch (error) {
    if (shouldLog) {
      console.log('--- CUSTOM EVENT ERROR: ', error);
    }
  }
};

export const dispatchMetricEvent = (baseEvent: Metric) => {
  try {
    switch (baseEvent.type) {
      case 'SAIKA': {
        if (baseEvent.id === 'page:load') {
          requestSaikaPageVisit(baseEvent.body);
        } else if (baseEvent.id === 'freight:calculate') {
          requestSaikaFreightVisualization(baseEvent.body);
        }
        break;
      }
      case 'TAG': {
        const dataLayer = window?.dataLayer ?? [];

        const navigation: Pick<Navigation, 'url' | 'page' | 'title' | 'referrer'> & {
          pageType: string;
        } = {
          referrer: window.document.referrer,
          url: window.location.href,
          page: window.location.pathname,
          title: window.document.title,
          pageType:
            {
              '/carrinho/': 'Carrinho',
              '/pagamento/': 'Pagamento',
              '/pagamento/endereco/': 'Endereço',
              '/pagamento/confirmacao/': 'Confirmação',
              '/pagamento/obrigado': 'Obrigado',
            }[window.location.pathname] || '',
        };

        const googleMetric = {
          ...navigation,
          ...buildFrontMetricPayload(),
          ...baseEvent.payload,
        };

        if (shouldLog) {
          console.log('--- CUSTOM EVENT METRICS', {
            google: googleMetric,
          });
        }

        if (!isDevelopment) {
          dataLayer.push(googleMetric);
        }
        break;
      }
    }
  } catch (error) {
    if (shouldLog) {
      console.log('--- CUSTOM EVENT ERROR: ', error);
    }
  }
};

export const buildFrontMetricPayload = () => {
  return {
    page: window.top?.location.pathname,
    title: window.document.title,
    url: window.location.href,
  };
};
