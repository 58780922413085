import styled from 'styled-components';
import CONFIG from '../../generated-config';
import {
  getToggleFeatureValue,
  hasEditedConfig,
  cleanEditedConfig,
} from '../../services/config.service';
import ToggleFeatureToggle from './ToggleFeatureToggle.component';
import { BrandButton } from '../../components/brand-button.component';
import { useDebug } from '../Debug.context';
import Notification from '../../notification/notification';

type ToggleFeatureTuple = [keyof typeof CONFIG.toggleFeatures, boolean];

const LOCALE = CONFIG.locale.debugMode.CONFIG_LIST;

const DebugConfigListComponent = () => {
  const { changeDebugMode } = useDebug();
  const toggleFeatures = Object.entries(CONFIG.toggleFeatures) as ToggleFeatureTuple[];
  const isEditedConfig = hasEditedConfig();

  return (
    <>
      <DebugModalTitle className="--ignore-case">{LOCALE.title}</DebugModalTitle>
      {isEditedConfig && <Notification type="WARNING">{LOCALE.editedConfigWarning}</Notification>}
      {toggleFeatures.map(v => {
        const toggleFeature = { key: v[0], value: getToggleFeatureValue(v[0]) };
        return (
          <ToggleFeatureToggle key={`toggle-feature-item__${v[0]}`} toggleFeature={toggleFeature} />
        );
      })}
      <ActionButtonsWrapper>
        <BrandButton
          style={{ marginRight: '15px', fontSize: '1rem' }}
          buttonType="primary"
          onClick={() => {
            cleanEditedConfig();
            window.location.reload();
          }}
        >
          {LOCALE.restoreDefaultConfig}
        </BrandButton>
        <BrandButton buttonType="secondary" onClick={() => changeDebugMode('MODE_LIST')}>
          voltar
        </BrandButton>
      </ActionButtonsWrapper>
    </>
  );
};

const DebugModalTitle = styled.h3`
  text-align: center;
  padding-bottom: 20px;
  font-size: 24px;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 15px auto 0px auto;
`;

export default DebugConfigListComponent;
