import { css } from 'styled-components';

export default css`
  .new-modal-backdrop {
    backdrop-filter: blur(2px);
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5vh 0 50px;
    justify-content: center;
    overflow-y: hidden;
    align-items: flex-start;
    max-height: 100vh;

    @media (max-height: 760px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    @media (max-width: 768px) {
      padding: 0px;
      align-items: flex-start;
    }

    &.--opening {
      display: flex;
      animation: fade-in 150ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
      z-index: 11;

      > .new-modal {
        animation: slide-in-bottom 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 150ms both;
      }
    }

    &.--open {
      display: flex;
      opacity: 1;
      z-index: 11;

      > .new-modal {
        opacity: 1;
        position: relative;
        z-index: 10;
      }
    }

    &.--closing {
      display: flex;
      opacity: 1;
      z-index: 11;
      animation: fade-out 150ms ease-out 250ms both;

      > .new-modal {
        animation: slide-out-bottom 250ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      }
    }
  }

  .new-modal {
    --padding: 28px;
    --closeIconSize: 16px;
    background-color: #fff;
    padding: var(--padding);
    opacity: 0;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;

    & > .close-icon {
      cursor: pointer;
      position: absolute;
      right: calc(var(--padding) - var(--closeIconSize));
      top: calc(var(--padding) - var(--closeIconSize));
      width: var(--closeIconSize);
      height: auto;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .new-modal-backdrop .new-modal.new-conflict-modal {
    padding: 0;
    border-radius: 1rem;
    width: 100%;
    max-width: 500px;

    @media (max-width: 768px) {
      max-height: 95vh;
      align-self: end;
    }
  }
`;
