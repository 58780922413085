import CONFIG from 'generated-config';
import { getContextCookies, getCookie } from 'services/cookie/cookie.service';

type ScreenMap = { [key in ScreenNames]: PageType };
const METRIC_SCREEN_MAP: ScreenMap & { BASKET_SERVICE: PageType } = {
  BASKET: 'Carrinho',
  ADDRESS: 'Endereço',
  CONFIRMATION: 'Confirmacao',
  PAYMENT: 'Pagamento',
  THANKS: 'Obrigado',
  COMPLETE_SIGNUP: 'Pagamento',
  LOGIN: 'Pagamento',
  BASKET_SERVICE: 'GarantiaProdutoModal',
};

export const buildDefaultMetricContext = (
  screen: ScreenNames | 'BASKET_SERVICE',
): Omit<BaseMetricPayload, 'eventAction' | 'eventCategory'> => {
  const safeMeta = JSON.parse(localStorage.getItem('sessionMeta') ?? '{}');
  const sessionMeta = safeMeta.bffVersion === 'v1' ? safeMeta : undefined;
  const b2wContext = getContextCookies();
  const customerId = getCookie(CONFIG.cookiesDict.customerId);

  const eparElements = b2wContext.b2wEPar?.split('_');
  const brand = CONFIG.brand;

  return {
    pagetype: METRIC_SCREEN_MAP[screen],
    pagename: `${brand}:${METRIC_SCREEN_MAP[screen]}`,
    eventLabel: undefined,
    eventValue: undefined,
    'b2w-pid': b2wContext.b2wPid,
    'b2w-uid': b2wContext.b2wUid,
    customerID: customerId,
    AppID: 'NAOMOBILE',
    'b2w-sid': b2wContext.b2wSid,
    b2wChannel: b2wContext.b2wChannel,
    b2wRegion: b2wContext.b2wRegion,
    cfinance: b2wContext.c_finance,
    chave: b2wContext.b2wChave,
    epar: b2wContext.b2wEPar,

    cep: sessionMeta.zipCode ?? '',
    franq: b2wContext.b2wFranq,
    marca: CONFIG.brand,
    opn: b2wContext.b2wOpn,
    region: b2wContext.b2wRegion,
    gclid: b2wContext.gclid,

    campanha: eparElements.slice(4).join('_'),
    formato: eparElements[1],
    midia: eparElements[0],
    parceiro: eparElements[3],
    subformato: eparElements[2],
    'versão-site': 'Responsivo',
  };
};

export const buildMetric = (
  metricProps: {
    event: string;
    eventAction: string;
    eventLabel: string;
    eventCategory: string;
  } & Record<string, unknown>,
  screen: ScreenNames | 'BASKET_SERVICE',
): TagMetric => {
  return {
    id: metricProps.event,
    type: 'TAG',
    payload: {
      ...buildDefaultMetricContext(screen),
      ...metricProps,
    },
  };
};
