import {
  queryCache,
  QueryFunction,
  ReactQueryMutationsConfig,
  useMutation,
  useQuery,
} from 'react-query';
import { BFF_ACTION_MANAGER } from './useGlobalFetching';
import {
  SessionStandAloneRequest,
  setStandAloneSessionCache,
  standAloneAction,
} from '../../services/bff/bff.service';
import { MutationResult } from 'react-query';
import { dispatchPageAction } from '../../services/metrics/datadog';
import { useDebug } from '../../debug/Debug.context';
import { metricsMiddleware } from 'services/metrics/middleware';

type EventData = { [key: string]: string | number | undefined };

type DoActionFunction<P, T = Session> = (
  palyoad: P,
  eventData?: EventData,
) => Promise<T | undefined>;

export const useBffStandAloneAction = <P extends StandaloneAction['payload']>(
  screen: SessionScreen,
  ACTION_TYPE: StandaloneAction['type'],
  mutateConfig?: ReactQueryMutationsConfig<Session>,
): [DoActionFunction<P>, MutationResult<Session>] => {
  const { data: sessionMeta } = useQuery<SessionMeta>(
    ['SESSION_META', screen],
    (() => {}) as QueryFunction<SessionMeta>,
    {
      initialData: () => queryCache.getQueryData(['SESSION_META', screen]),
      enabled: false,
    },
  );

  const { addDebugEntryLog } = useDebug();
  const [mutationFn, ...mutationRest] = useMutation(
    (requestPayload: SessionStandAloneRequest & { eventData?: EventData }) => {
      BFF_ACTION_MANAGER.addAction();
      return standAloneAction(requestPayload);
    },
    {
      ...mutateConfig,
      onSuccess: (data, variables) => {
        dispatchPageAction(`${screen}:standalone:${ACTION_TYPE}`, {
          status: 'success',
          actionType: ACTION_TYPE,
          sessionMeta: JSON.stringify(variables.meta),
          newSessionMeta: JSON.stringify(data.meta),
          ...variables.eventData,
        });

        addDebugEntryLog({
          ...data,
          action: ACTION_TYPE,
          actionPayload: variables.action.payload,
          status: 'success',
          type: 'BFF_LOG',
        });

        // @ts-ignore
        setStandAloneSessionCache(data, variables.meta, variables.action);
        if (typeof mutateConfig?.onSuccess === 'function') {
          mutateConfig.onSuccess(data, variables);
        }
      },
      onError: async (error: BffErrorResponse, variables, rollback) => {
        dispatchPageAction(`${screen}:standalone:${ACTION_TYPE}`, {
          status: 'error',
          actionType: ACTION_TYPE,
          sessionMeta: JSON.stringify(variables.meta),
          newSessionMeta: JSON.stringify(error?.response?.data?.meta ?? {}),
          ...variables.eventData,
        });

        //@ts-ignore
        const failedSession = ((error?.response?.data ?? {}) as any) as FailedSession;
        addDebugEntryLog({
          ...failedSession,
          action: ACTION_TYPE,
          actionPayload: variables.action.payload,
          status: 'error',
          type: 'BFF_LOG',
        });

        if (typeof mutateConfig?.onError === 'function') {
          return await mutateConfig.onError(error, variables, rollback);
        } else if (typeof rollback === 'function') {
          return await rollback();
        }
      },
      onSettled: (response, ...args) => {
        metricsMiddleware(response);
        BFF_ACTION_MANAGER.removeAction();
        if (typeof mutateConfig?.onSettled === 'function') {
          mutateConfig.onSettled(response, ...args);
        }
      },
    },
  );

  return [
    (payload, eventData) => {
      if (sessionMeta) {
        return mutationFn({
          action: {
            type: ACTION_TYPE,
            payload,
          },
          meta: sessionMeta,
          eventData,
        });
      } else {
        console.error(
          `SESSION_META not found for screen<${screen}> with actionType<${ACTION_TYPE}>`,
        );

        return Promise.reject();
      }
    },
    ...mutationRest,
  ];
};

export default useBffStandAloneAction;
