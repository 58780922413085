import React, { useState } from 'react';
import styled from 'styled-components';

type ToggleComponentProps = {
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
};

const ToggleComponent: React.FC<ToggleComponentProps> = ({ onChangeHandler, checked }) => {
  const [isChecked, setChecked] = useState(checked);

  return (
    <>
      <ToggleWrapper>
        <ToggleAnchor
          checked={isChecked}
          onChange={e => {
            onChangeHandler(e);
            setChecked(!isChecked);
          }}
        />
        <Toggle />
      </ToggleWrapper>
    </>
  );
};

const ToggleAnchor = styled.input.attrs(() => ({ type: 'checkbox' }))`
  display: none;
`;

const Toggle = styled.div`
  width: 35px;
  height: 10px;
  border-radius: 15px;
  background-color: #e8e8e8;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease;

  ::before {
    top: -4.5px;
    box-sizing: border-box;
    content: '';
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.04);
    transition: transform 0.1s ease;
  }
`;

const ToggleWrapper = styled.label`
  ${ToggleAnchor}:checked ~ ${Toggle} {
    background-color: #66ce02;
  }
  ${ToggleAnchor}:checked ~ ${Toggle}::before {
    transform: translateX(18px);
  }
`;

export default ToggleComponent;
