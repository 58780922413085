import CONFIG from '../../generated-config';

const defaultFreeMessage = CONFIG.locale.freePriceText;

export const toPrice = (price: number, freeMessage = defaultFreeMessage): string => {
  if (price === 0) {
    return freeMessage;
  }

  if (!Boolean(price)) {
    return '';
  }

  return `${price < 0 ? ' - ' : ''}R$ ${Math.abs(price)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`;
};

export const toFreightPriceMessage = (price: number): string => {
  return price === 0 ? `com frete grátis` : `por ${toPrice(price)}`;
};
