import { datadogRum } from '@datadog/browser-rum';

const initDatadog = () => {
  datadogRum.init({
    applicationId: 'f25aecac-2639-451a-b66d-72fcc99889cc',
    clientToken: '123',
    site: 'datadoghq.com',
    service: 'tela-de-pagamento',
    sampleRate: 4,
    trackInteractions: true,
  });
};

export default initDatadog;
