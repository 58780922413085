import CONFIG from '../generated-config';

type ToggleKey = keyof typeof CONFIG.toggleFeatures;

export const getToggleFeatureValue = (toggleKey: ToggleKey) => {
  const localConfig = localStorage.getItem(toggleKey);

  if (Boolean(localConfig) && isDebugMode()) return Boolean(localConfig === 'true');

  return CONFIG.toggleFeatures[toggleKey];
};

export const isDebugMode = () => window.location.search.includes('_debug=true');

export const hasEditedConfig = () => {
  return (
    Object.keys(CONFIG.toggleFeatures)
      .map(v => localStorage.getItem(v))
      .filter(v => v !== null).length > 0
  );
};

export const cleanEditedConfig = () =>
  Object.keys(CONFIG.toggleFeatures).map(v => localStorage.removeItem(v));
