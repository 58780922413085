import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './generated-config';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from './modal/index';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { PaymentContextProvider } from './payment-screen/payment-menu/paymentMenu.context';
import { DefinedCreditCardProvider } from './payment-screen/payment-menu/components/creditCard/definedCreditCard/definedCreditCard.context';
import { DebugContextProvider } from './debug/Debug.context';
import DebugButton from './debug/components/DebugButton.component';
import { FreightProvider } from './basket-screen/freight/freight.context';
import datadogInit from './services/datadog/init';
import './polyfills';
import { FullModalContainer, FullModalProvider } from 'tega-components';
import { FloatNotificationProvider } from 'hooks/useFloatNotification';

// a config do react query provider não aceita uma config com genericos :( //force
const queryConfig: ReactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {},
  },
};

datadogInit();

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    <DebugContextProvider>
      <ThemeProvider theme={config.theme}>
        <FloatNotificationProvider>
          <ModalProvider>
            <FullModalProvider>
              <FreightProvider>
                <Router>
                  <PaymentContextProvider>
                    <DefinedCreditCardProvider>
                      <App />
                      <DebugButton />
                    </DefinedCreditCardProvider>
                    <ReactQueryDevtools />
                  </PaymentContextProvider>
                </Router>
              </FreightProvider>
              <FullModalContainer />
            </FullModalProvider>
          </ModalProvider>
        </FloatNotificationProvider>
      </ThemeProvider>
    </DebugContextProvider>
  </ReactQueryConfigProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

export type Locale = typeof config.locale;
