import NewCardNotificationComponent from 'notification/new-card-notification';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

type FloatNotificationPortalProps = {
  closeFloatNotificationHandler: () => void;
  animationClassName: string;
  onBackdropAnimationEnd?: () => void;
  onStageAnimationEnd?: () => void;
  wrapperClassName?: string;
  enableCloseOnEsc?: boolean;
  enableClose?: boolean;
  floatNotificationDataCy?: string;
  children?: React.ReactNode;
  timeout?: number;
  type: WarningType;
};

const FloatNotificationPortal: React.FC<FloatNotificationPortalProps> = ({
  onStageAnimationEnd,
  wrapperClassName = '',
  closeFloatNotificationHandler,
  children,
  enableClose,
  floatNotificationDataCy,
  type,
  timeout,
}) => {
  const floatNotificationRootNode = document.getElementById('modal-root');
  if (timeout) {
    setTimeout(closeFloatNotificationHandler, timeout);
  }

  return (
    floatNotificationRootNode &&
    ReactDOM.createPortal(
      <NotificationComponent
        type={type}
        className={`new-floatNotification ${wrapperClassName}`}
        //onAnimationEnd={onStageAnimationEnd}
        data-cy={floatNotificationDataCy}
        disableClose={!enableClose}
      >
        {children}
      </NotificationComponent>,
      floatNotificationRootNode,
    )
  );
};

export default FloatNotificationPortal;
const NotificationComponent = styled(NewCardNotificationComponent)`
  position: fixed;
  bottom: 1rem;
  width: 90%;
  max-width: 330px;
  right: 0;
  left: 0;
  margin: 0 auto;
  justify-content: start;
`;
