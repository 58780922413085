import CONFIG from '../../generated-config';
import { PaymentMenuComponentExtractedProps, PaymentMenuOption } from './paymentMenu.component';
import { ReactComponent as BankSlipIcon } from '../../assets/icons/payment-options/bank-slip-icon.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/payment-options/credit-card-icon.svg';
import { ReactComponent as BankSlipLasaIcon } from '../../assets/icons/payment-options/O2O-icon.svg';
import { BFF_DEFAULT_BEHAVIOURS } from '../../services/bff/bff.service';
import { ReactComponent as PixIcon } from '../../assets/icons/payment-options/pix-icon.svg';
import { ReactComponent as CorporateIcon } from '../../assets/icons/payment-options/corporate-icon.svg';
import { ReactComponent as WalletInstallmentIcon } from '../../assets/icons/payment-options/icon-carne-ame.svg';
import WalletIcon from '../components/svg/ame-brand';
import { toPrice } from '../../services/price/toPriceMessage.helper';

type PaymentIconMap = { [K in PaymentMenuOptionId]: React.ReactChild };
type iconProps = {
  className: string;
};

const defaultPaymentIconsProps: iconProps = {
  className: 'payment-icon',
};
const LOCALE = CONFIG.locale['payment-options']['payment-menu'];
const PAYMENT_ICONS: PaymentIconMap = {
  BANK_SLIP: <BankSlipIcon {...defaultPaymentIconsProps} />,
  CREDIT_CARD: <CreditCardIcon {...defaultPaymentIconsProps} />,
  BANK_SLIP_LASA: <BankSlipLasaIcon {...defaultPaymentIconsProps} />,
  PIX: <PixIcon {...defaultPaymentIconsProps} />,
  WALLET: <WalletIcon className="ame-icon" />,
  WALLET_BANK_SLIP: <WalletInstallmentIcon className="ame-icon" />,
  CORPORATE: <CorporateIcon {...defaultPaymentIconsProps} />,
};

const extractPaymentMenu = (
  paymentMenuComponent?: PaymentMenuComponent,
): PaymentMenuComponentExtractedProps => {
  return {
    options: getPaymentOptions(paymentMenuComponent),
    behaviour: paymentMenuComponent?.behaviour ?? BFF_DEFAULT_BEHAVIOURS.HIDDEN,
    selectedOptionId: getSelectedOptionId(paymentMenuComponent),
  };
};

const getPaymentOptions = (paymentMenu?: PaymentMenuComponent): PaymentMenuOption[] => {
  const paymentOptions = paymentMenu?.payload ?? [];

  return paymentOptions.reduce((acc, paymentOption) => {
    if (!paymentOption.id) {
      return acc;
    }
    return [...acc, buildPaymentOption(paymentOption)];
  }, [] as PaymentMenuOption[]);
};

const getSelectedOptionId = (
  paymentMenuComponent?: PaymentMenuComponent,
): PaymentMenuOptionId | 'NO_SELECTED_OPTION' => {
  const options = paymentMenuComponent?.payload ?? [];
  return options.find(({ behaviour }) => behaviour === 'SELECTED')?.id ?? 'NO_SELECTED_OPTION';
};

const buildPaymentOption = (paymentOption: PaymentMenuOptionComponent): PaymentMenuOption => {
  return {
    id: paymentOption.id,
    title: LOCALE.option.title[paymentOption.id],
    icon: PAYMENT_ICONS[paymentOption.id],
    selected: paymentOption.behaviour === 'SELECTED',
    promotions:
      paymentOption?.payload?.promotions?.map(promotion =>
        buildPromotionMessage(paymentOption, promotion),
      ) ?? [],
    amount: paymentOption?.payload?.amount ?? 0,
  };
};

const buildPromotionMessage = (
  paymentOption: PaymentMenuOptionComponent,
  promotion: PaymentMenuOptionPromotion,
) => {
  const { value: promotionValue, type } = promotion ?? {};

  if (!promotionValue) {
    return null;
  }

  switch (paymentOption.id) {
    case 'WALLET_BANK_SLIP':
      return type === 'CASHBACK' ? (
        <>
          <span className="wallet-promotion --ignore-case">{toPrice(promotionValue)}</span>{' '}
          {LOCALE.option.promotion[type]}
        </>
      ) : null;

    case 'CREDIT_CARD':
      if (type === 'NOMINAL') {
        return (
          <>
            <span className="--ignore-case">{toPrice(promotionValue)}</span> de desconto em 1x
          </>
        );
      }
      if (type === 'CASHBACK') {
        return (
          <>
            <span className="--ignore-case">{toPrice(promotionValue)}</span>
            {LOCALE.option.promotion[type]}
          </>
        );
      }
      return null;
    case 'BANK_SLIP':
    case 'BANK_SLIP_LASA':
    case 'CORPORATE':
    case 'PIX':
    case 'WALLET':
      return (
        <>
          <span className="--ignore-case">{toPrice(promotionValue)}</span>
          {LOCALE.option.promotion[type]}
        </>
      );

    default:
      return null;
  }
};

export default extractPaymentMenu;
