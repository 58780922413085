import { useHistory, useLocation } from 'react-router-dom';
import CONFIG from '../generated-config';
import cookies from 'js-cookie';
import { isDebugMode, cleanEditedConfig } from '../services/config.service';
import { clearCachedComponents } from 'services/cache-components/cache-components.service';

const routerRedirectConfig = CONFIG.router;
const pathNameToSessionDictionary = CONFIG.pathnameToSessionScreen;

export const useScreenRedirect = (actionType?: Action['type']) => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname as keyof typeof pathNameToSessionDictionary;
  return ({
    userScreen,
    session: { screen: sessionScreen, meta },
  }: {
    userScreen?: SessionScreen;
    session: SuccessfulSession;
  }) => {
    const currentScreen = userScreen ?? pathNameToSessionDictionary[pathname];
    const urlParams = new URLSearchParams(window.location.search);

    const isApp = urlParams.get('utm_medium=app') || urlParams.get('app_medium=app');

    const isDebug = isDebugMode();

    if (currentScreen === sessionScreen) return;

    if (sessionScreen === 'THANKS') {
      const thanksSessionMeta: Partial<ThanksSessionMeta> = meta;

      cookies.set('orderId', thanksSessionMeta?.orderId ?? '', { domain: CONFIG.cookiesDomain });
      clearCachedComponents();
      cleanEditedConfig();
    }

    if (sessionScreen === 'LOGIN') {
      if (isDebug) return;
      if (actionType === 'GO_TO_PAYMENT') {
        window.location.assign(`${CONFIG.urls.loginUrl}?next=${CONFIG.urls.paymentUrl}`);
        return;
      }
      window.location.assign(`${CONFIG.urls.loginUrl}?next=${window.location.href}`);
      return;
    }

    if (sessionScreen === 'BASKET') {
      if (isDebug) return;
      if (isApp) {
        window.location.assign(CONFIG.urls.basketDeeplink);
        return;
      }
      if (CONFIG.brand === 'aemp' || CONFIG.brand === 'prtl') {
        window.location.assign(CONFIG.urls.basketUrl);
        return;
      }
    }

    if (sessionScreen === 'COMPLETE_SIGNUP') {
      if (actionType === 'GO_TO_PAYMENT') {
        window.location.assign(`${CONFIG.urls.loginUrl}?next=${CONFIG.urls.paymentUrl}`);
        return;
      }
      window.location.assign(
        `${CONFIG.urls.loginUrl}completar-cadastro/?next=${window.location.href}`,
      );
      return;
    }

    history.push(routerRedirectConfig[sessionScreen] + history.location.search);
  };
};
