import CONFIG from '../../generated-config';
import Optional from 'optional-js';

export default function getConfig<T = Config>(path: string[], defaultValue?: T): T {
  return path.reduce((accum: any, curr: string): string => {
    return Optional.ofNullable(accum)
      .map(accum => accum[curr])
      .orElse(defaultValue);
  }, CONFIG as any);
}
