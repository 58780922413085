import { InitialLoaderProps } from '../initial-loader.component';
import styled, { keyframes, css } from 'styled-components';
import { QUERY_STATUS } from '../../../services/bff/bff.service';

const SubaInitialLoaderComponent: React.FC<InitialLoaderProps> = ({
  queryStatus,
  setPaymentScreenStatus,
}) => {
  const queryFinished = queryStatus === QUERY_STATUS.SUCCESS || queryStatus === QUERY_STATUS.ERROR;

  return (
    <Loader>
      <LogoSvg
        onAnimationEnd={() => {
          queryFinished && setPaymentScreenStatus(queryStatus);
        }}
        shouldGoOut={queryFinished}
        viewBox="0 0 24.58 13.06"
      >
        <LogoSvgPath d="M21.36,6.61H17.41V5.53a1.68,1.68,0,0,0-1.72-1.7H14.1V2.17c0-.51.09-.56.81-.56a.82.82,0,0,0,.81-.8A.81.81,0,0,0,15,0H13.3a.81.81,0,0,0-.81.81v3H10.83a1.65,1.65,0,0,0-1.7,1.7V6.61H5.24A3.23,3.23,0,0,0,2.13,9H1.61V8.23A.81.81,0,0,0,0,8.23v3.23a.81.81,0,0,0,1.61,0v-.81h.53a3.22,3.22,0,0,0,3.1,2.41H21.36a3.23,3.23,0,0,0,0-6.45Z"></LogoSvgPath>
      </LogoSvg>
      <WaterWrapper shouldCompletelyFill={queryFinished}>
        <WaterSvg viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
          <WaterSvgPath d="M300.5 300V2.5C300.5 2.5 299.9 2.4 299.4 2.4C299.4 2.4 273.9 0.1 258.9 0C243.9 0 218.3 2.4 218.3 2.4C206 3.5 197 4.3 186.4 4.3C177 4.3 166.7 3.5 154.4 2.4C154.4 2.4 128.6 0.1 113.6 0C98.6 0 72.8 2.4 72.8 2.4C60.5 3.5 48.5 4.3 40.8 4.3C33.1 4.3 20.8 3.5 8.6 2.4C8.6 2.4 5.5 2.1 0.5 1.7V300H300.5Z"></WaterSvgPath>
        </WaterSvg>
      </WaterWrapper>
      <LoadingText shouldShow={!queryFinished}>Estamos montando seu pagamento...</LoadingText>
    </Loader>
  );
};

const Loader = styled.div`
  margin: 0 auto;
  width: 100%;
  background: #e5e5e5;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
`;

const logoIn = keyframes`
  0% { transform: translate(0, 1000px) rotate(-10deg); }

  60% {
    transform: translate(0, 200px) rotate(-10deg);
  }

  100% {
    transform: translate(0, 350px) rotate(0);
  }
`;

const subaWave = keyframes`
  0% {
    transform: translate(0, 350px) rotate(0deg);
  }

  25% {
    transform: translate(0, 380px)  rotate(10deg);
  }

  50% {
    transform: translate(0, 410px) rotate(0deg);
  }

  75% {
    transform: translate(0, 380px) rotate(-10deg);
  }

  100% {
    transform: translate(0, 350px) rotate(0deg);
  }
`;

const logoOut = keyframes`
  0% {
    transform: translate(0, 350px) rotate(10deg);
  }
  20% {
    transform: translate(0, 300px) rotate(10deg) scale(1);
  }
  65% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 500px) rotate(25deg) scale(0.8);
    opacity: 0;
  }
`;

const LogoSvg = styled.svg<{ shouldGoOut: boolean }>`
  position: absolute;
  width: 200px;
  left: calc(50% - 100px);
  transform: translate(0, 1000px) rotate(-10deg);
  animation: ${({ shouldGoOut }) =>
    shouldGoOut
      ? css`
          ${logoOut} 0.3s ease forwards
        `
      : css`
          ${logoIn} 1s 0.3s ease forwards, ${subaWave} 3s 1.3s linear infinite;
        `};
`;

const LogoSvgPath = styled.path`
  fill: white;
`;

const fillWater = keyframes`
  0% {
    transform: translate(0, 1000px);
  }
  100% {
    transform: translate(0, 400px);
  }
`;

const completelyFillWater = keyframes`
  0% {
    transform: translate(0, 400px);
  }
  100% {
    transform: translate(0, -200px);
  }
`;

const WaterWrapper = styled.div<{ shouldCompletelyFill: boolean }>`
  animation: ${({ shouldCompletelyFill }) =>
    shouldCompletelyFill
      ? css`
          ${completelyFillWater} 0.3s ease forwards
        `
      : css`
          ${fillWater} 1s ease forwards
        `};
`;

const WaterSvg = styled.svg`
  width: 4000px;
  @media (max-width: 768px) {
    width: 3000px;
  }
`;

const waveAction = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-145px, 0);
  }
`;

const WaterSvgPath = styled.path`
  animation: ${waveAction} 3s linear infinite;
  height: 150px;
  fill: #0433ff;
  opacity: 0.75;
`;

const fadeIn = keyframes`
  45% {
    opacity: 0
  }
  70% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
`;

const LoadingText = styled.p<{ shouldShow: boolean }>`
  opacity: 0;
  text-align: center;
  animation: ${fadeIn} 2.2s both;
  color: ${props => (props.shouldShow ? 'white' : 'transparent')};
  position: absolute;
  left: calc(50% - 133px);
  top: calc(50% + 100px);
`;

export default SubaInitialLoaderComponent;
