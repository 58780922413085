import React, { useContext } from 'react';
import { ModalContext } from './modal.context';
import ModalPortal from './components/modal-portal.component';
import styled from 'styled-components';
import { ReactComponent as ErrorSVG } from '../assets/icons/header-modal-error-icon.svg';
import { ReactComponent as CloseSVG } from '../assets/icons/header-modal-error-icon.svg';
import { ReactComponent as WarningSVG } from '../assets/icons/icon-alert-warning.svg';
import { ReactComponent as InfoSVG } from '../assets/icons/icon-alert-info.svg';
import { ReactComponent as SuccessSVG } from '../assets/icons/icon-alert-success.svg';

const ModalContainer = () => {
  const { state, closeModal, onAnimationEnd } = useContext(ModalContext);
  const { currentStatus, component, options } = state;

  const DEFAULT_ANIMATION_CLASSES = {
    CLOSED: '--closed',
    OPENING: '--opening',
    OPEN: '--open',
    CLOSING: '--closing',
  };

  const MODAL_HEADER_ICONS_MAP = {
    ERROR: <ErrorSVG />,
    WARNING: <WarningSVG />,
    INFO: <InfoSVG />,
    SUCCESS: <SuccessSVG />,
  };

  // TODO: determine appropriate colors for Warning, Info and Success as needed.
  const MODAL_HEADER_BG_COLOR_MAP = {
    ERROR: '#F80032',
    WARNING: '#F80032',
    INFO: '#F80032',
    SUCCESS: '#F80032',
  };

  const defaultHandler = () => {};

  const defaultModalProps = {
    closeModalHandler: closeModal || defaultHandler,
    modalDataCy: options.modalDataCy,
    hideCloseIcon: options.hideCloseIcon,
    enableCloseOnEsc: options.enableCloseOnEsc,
    enableBackDropClick: options.enableBackDropClick,
    animationClassName: DEFAULT_ANIMATION_CLASSES[currentStatus],
    wrapperClassName: options.wrapperClassName,
    enableClose: options.enableClose,
  };

  // TODO: tirar do corpo do componente, chamar como um componente para evitar renders desnecessários.
  const modalHeader = () => {
    if (state.options.modalHeader) {
      const header = state.options.modalHeader;
      return (
        <ModalHeader bgColor={MODAL_HEADER_BG_COLOR_MAP[header.type]}>
          <HeaderIcon onClick={() => closeModal()}>
            {MODAL_HEADER_ICONS_MAP[header.type]}
          </HeaderIcon>
          <HeaderMessage>{header.message}</HeaderMessage>
          <CloseButton>
            <CloseSVG onClick={() => closeModal()} />
          </CloseButton>
        </ModalHeader>
      );
    }
    return null;
  };

  return {
    CLOSED: null,
    OPENING: (
      <ModalPortal
        {...defaultModalProps}
        onStageAnimationEnd={onAnimationEnd}
        hasHeader={!!state.options.modalHeader}
        wrapperClassName={state.options.wrapperClassName}
      >
        {modalHeader()}
        {component}
      </ModalPortal>
    ),
    OPEN: (
      <ModalPortal
        {...defaultModalProps}
        wrapperClassName={state.options.wrapperClassName}
        hasHeader={!!state.options.modalHeader}
      >
        {modalHeader()}
        {component}
      </ModalPortal>
    ),
    CLOSING: (
      <ModalPortal
        {...defaultModalProps}
        wrapperClassName={state.options.wrapperClassName}
        onBackdropAnimationEnd={onAnimationEnd}
        hasHeader={!!state.options.modalHeader}
      >
        {modalHeader()}
        {component}
      </ModalPortal>
    ),
  }[currentStatus || 'CLOSED'];
};

const ModalHeader = styled.div<{ bgColor: string }>`
  background-color: ${props => props.bgColor};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 70px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  > svg {
    > path {
      fill: white;
      cursor: pointer;
    }
  }
`;

const HeaderIcon = styled.div`
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 7.5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    padding: 2.5px;
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

const HeaderMessage = styled.p`
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0 28px;
    font-size: 12px;
  }
`;

export default ModalContainer;
