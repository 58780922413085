import { queryCache, useQuery } from 'react-query';

export const useHasSessionMeta = (screen: Screens['name']) => {
  const { data: sessionMeta } = useQuery(['SESSION_META', screen], () => {}, {
    initialData: () => queryCache.getQueryData(['SESSION_META', screen]),
    enabled: false,
  });

  return Boolean(sessionMeta);
};
