import styled from 'styled-components';

type LoaderProps = {
  width: number;
  height: number;
  borderColor?: string;
};

const Loader: React.FC<LoaderProps> = props => {
  return (
    <LoaderWrapper {...props} className="loader">
      <SpinnerTop {...props}>
        <SpinnerRotator {...props}></SpinnerRotator>
      </SpinnerTop>
      <SpinnerBottom {...props}>
        <SpinnerRotator {...props}></SpinnerRotator>
      </SpinnerBottom>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div<LoaderProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
`;

const SpinnerTop = styled.div<LoaderProps>`
  overflow: hidden;
  height: ${props => `${props.height / 2}px`};
  width: ${props => `${props.width}px`};
`;
const SpinnerBottom = styled.div<LoaderProps>`
  overflow: hidden;
  height: ${props => `${props.height / 2}px`};
  width: ${props => `${props.width}px`};
  transform: rotate(180deg) scale(-1, 1);
`;

const SpinnerRotator = styled.div<LoaderProps>`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  border: 2.5px solid transparent;
  border-right-color: ${props => (props.borderColor ? props.borderColor : props.theme.brandColor)};
  border-top-color: ${props => (props.borderColor ? props.borderColor : props.theme.brandColor)};
  border-radius: 50%;
  box-sizing: border-box;
  animation: spinner-animation 1.5s ease-in-out infinite;
  transform: rotate(-200deg);

  @keyframes spinner-animation {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.3;
    }
    50% {
      transform: rotate(115deg);
      opacity: 1;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default Loader;
