import React, { Suspense, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import CONFIG from './generated-config';
import { useCreateBffSession } from 'hooks/bff';
import { QUERY_STATUS } from 'services/bff/bff.service';
import InitialLoader from 'payment-screen/initial-loader/initial-loader.component';
import { buildCreateSessionQueryParams } from 'services/create-session/create-session.service';
import GlobalStyles from 'global-styles';
import useHotjarHook from 'hooks/useHotjar.hook';

const addressScreenPromise = import('./address-screen/address-screen.component');
const AddressScreen = React.lazy(() => addressScreenPromise);
const paymentScreenPromise = import('./payment-screen/payment-screen.component');
const PaymentScreen = React.lazy(() => paymentScreenPromise);
const confirmationScreenPromise = import('./confirmation-screen/confirmation-screen.component');
const ConfirmationScreen = React.lazy(() => confirmationScreenPromise);
const thanksScreenPromise = import('./thanks-screen/thanks-screen.component');
const ThanksScreen = React.lazy(() => thanksScreenPromise);
const basketScreenPromise = import('./basket-screen/basket-screen.component');
const BasketScreen = React.lazy(() => basketScreenPromise);

const App: React.FC = () => {
  const { screen, createSessionPayload, queryConfig } = buildCreateSessionQueryParams();

  const { status: createSessionStatus, hasSessionMeta } = useCreateBffSession(
    screen,
    createSessionPayload,
    queryConfig,
  );

  const initialQueryStatus =
    screen === 'BASKET' || hasSessionMeta ? QUERY_STATUS.SUCCESS : QUERY_STATUS.LOADING;

  const [controlledScreenStatus, setPaymentScreenStatus] =
    useState<QueryStatus>(initialQueryStatus);

  const location = useLocation();
  const shouldDisableLoader = location.search.includes('disableLoader');
  const queryStatus = shouldDisableLoader ? createSessionStatus : controlledScreenStatus;

  const getLoadingComponent = () => {
    return (
      <>
        <LoaderWrapper>
          <InitialLoader
            setPaymentScreenStatus={(status: QueryStatus) => setPaymentScreenStatus(status)}
            queryStatus={createSessionStatus}
            screen={createSessionPayload.screen}
          ></InitialLoader>
        </LoaderWrapper>
      </>
    );
  };

  useHotjarHook();

  switch (queryStatus) {
    case QUERY_STATUS.IDLE:
    case QUERY_STATUS.LOADING:
      return !shouldDisableLoader ? getLoadingComponent() : null;

    case QUERY_STATUS.SUCCESS:
      return (
        <Suspense fallback={null}>
          <GlobalStyles />
          <Switch>
            <Route exact path={CONFIG.router.BASKET} render={() => <BasketScreen />} />
            <Route exact path={CONFIG.router.PAYMENT} render={() => <PaymentScreen />} />
            <Route exact path={CONFIG.router.ADDRESS} render={() => <AddressScreen />} />
            <Route exact path={CONFIG.router.CONFIRMATION} render={() => <ConfirmationScreen />} />
            <Route exact path={CONFIG.router.THANKS} render={() => <ThanksScreen />} />
          </Switch>
        </Suspense>
      );

    case QUERY_STATUS.ERROR:
    default:
      return (
        <ErrorWrapper>Ops... Um erro inesperado ocorreu. Tente atualizar a página</ErrorWrapper>
      );
  }
};

const LoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.loaderBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
`;

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default App;
