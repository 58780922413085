declare namespace RISKX {
  const go: (path: string) => {};
}

function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

const sendBeacon = async (path: string, retry = 2) => {
  try {
    RISKX.go(path);
  } catch (error) {
    if (retry > 0) {
      setTimeout(() => sendBeacon(path, retry - 1), 2000);
    }
  }
};

export const security = {
  basketPageView: () => {
    sendBeacon(`/cart/${getCookieByName('cart.id')}`);
  },
  checkoutPageView: () => {
    sendBeacon(`/checkout/${JSON.parse(localStorage.getItem('sessionMeta') || '').checkoutId}`);
  },
  setPaymentOption: () => {
    sendBeacon(
      `/checkout/${JSON.parse(localStorage.getItem('sessionMeta') || '').checkoutId}/set-payment`,
    );
  },
  placeOrder: () => {
    sendBeacon(
      `/checkout/${JSON.parse(localStorage.getItem('sessionMeta') || '').checkoutId}/purchase`,
    );
  },
};
