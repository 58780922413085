import styled from 'styled-components';
import Toggle from '../../components/Toggle.component';

type ToggleFeatureToggleComponentProps = {
  toggleFeature: { key: string; value: boolean };
};

const ToggleFeatureToggleComponent: React.FC<ToggleFeatureToggleComponentProps> = ({
  toggleFeature,
}) => {
  return (
    <ToggleFeatureToggleWrapper>
      <p className="--ignore-case" style={{ marginRight: '20px' }}>
        {toggleFeature.key}
      </p>
      <Toggle
        checked={toggleFeature.value}
        onChangeHandler={e => {
          return e.target.checked
            ? localStorage.setItem(toggleFeature.key, 'true')
            : localStorage.setItem(toggleFeature.key, 'false');
        }}
      />
    </ToggleFeatureToggleWrapper>
  );
};

const ToggleFeatureToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
`;

export default ToggleFeatureToggleComponent;
