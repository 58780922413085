import { getOrigin } from '../device/device.service';
import cookies from 'js-cookie';
import CONFIG from '../../generated-config';

type CookiesDict = typeof CONFIG.cookiesDict;

export const setCookie = (key: keyof CookiesDict, value: string) => {
  try {
    const dictKey = CONFIG.cookiesDict[key];
    const cookieKey = typeof dictKey === 'string' ? dictKey : dictKey.cookie;
    cookies.set(cookieKey, value, {
      domain: CONFIG.cookiesDomain,
    });
  } catch (error) {
    console.error(`não foi possivel setar o cookie ${key}`);
    return;
  }
};

export const getCookie = (key: CookiesDict[keyof CookiesDict]) => {
  try {
    const cookieKey = typeof key === 'object' ? key.cookie : key;
    const queryParamKey = typeof key === 'object' ? key.queryParam : key;

    const queryParamValue = getQueryParam(cookieKey, queryParamKey);
    if (queryParamValue) return queryParamValue;

    return cookies.get(cookieKey) ?? '';
  } catch {
    console.error(`não foi possivel recuperar cookie ${key}`);
    return '';
  }
};

export const removeCookie = (key: CookiesDict[keyof CookiesDict]) => {
  try {
    const cookieKey = typeof key === 'object' ? key.cookie : key;

    if (cookieKey.includes('cart.id') && (CONFIG.brand === 'aemp' || CONFIG.brand === 'prtl')) {
      return cookies.remove(cookieKey, {
        domain: '.americanas.com.br',
      });
    }

    return cookies.remove(cookieKey, {
      domain: CONFIG.cookiesDomain,
    });
  } catch {
    console.error(`não foi possivel remover cookie ${key}`);
  }
};

const getQueryParam = (cookieKey: string, queryParamKey: string) => {
  const requestParams = new URLSearchParams(window.location.search);

  return requestParams.get(cookieKey)
    ? requestParams.get(cookieKey)
    : requestParams.get(queryParamKey);
};

const riskified = localStorage.getItem('rCookie') ?? localStorage.getItem('rCookie');
const getSafeRiskifiedDeviceId = riskified ? JSON.parse(riskified)?.value : '';

export const getContextCookies = (): ContextFromCookies => {
  return {
    b2wAbStorage: getCookie(CONFIG.cookiesDict.b2wABStorage),
    b2wChannel: getCookie(CONFIG.cookiesDict.b2wChannel),
    b2wEPar: getCookie(CONFIG.cookiesDict.b2wEPar),
    b2wSid: getCookie(CONFIG.cookiesDict.b2wSID),
    b2wPid: getCookie(CONFIG.cookiesDict.b2wPID),
    b2wUid: getCookie(CONFIG.cookiesDict.b2wUID),
    b2wDevice: getCookie(CONFIG.cookiesDict.b2wDevice),
    b2wDeviceType: getCookie(CONFIG.cookiesDict.b2wDeviceType) as B2wDeviceType,
    salesSolution: getCookie(CONFIG.cookiesDict.salesSolution) as SalesSolution,
    consumptionType: getCookie(CONFIG.cookiesDict.consumptionType) as ConsumptionType,
    employeeId: getCookie(CONFIG.cookiesDict.employeeId),
    forterToken: getCookie(CONFIG.cookiesDict.forterToken),
    telemarketerId: getCookie(CONFIG.cookiesDict.telemarketerId),
    orderKey: getCookie(CONFIG.cookiesDict.b2wChave),
    svi: getCookie(CONFIG.cookiesDict.S_VI),
    gtmSac: getCookie(CONFIG.cookiesDict.gtmSac),
    appMedium: getCookie(CONFIG.cookiesDict.appMedium),
    appSource: getCookie(CONFIG.cookiesDict.appSource),
    utmSource: getCookie(CONFIG.cookiesDict.utmSource),
    utmMedium: getCookie(CONFIG.cookiesDict.utmMedium),
    utmCampaign: getCookie(CONFIG.cookiesDict.utmCampaign),
    origin: getOrigin(),
    originVersion: getCookie(CONFIG.cookiesDict.appVersion),
    _ga: getCookie(CONFIG.cookiesDict._ga),
    _ga4: getCookie(CONFIG.cookiesDict._ga4),
    b2wChave: getCookie(CONFIG.cookiesDict.b2wChave),
    b2wRegion: getCookie(CONFIG.cookiesDict.b2wRegion),
    afpid: getCookie(CONFIG.cookiesDict.afpid),
    c_finance: getCookie(CONFIG.cookiesDict.c_finance),
    gclid: getCookie(CONFIG.cookiesDict.gclid),
    b2wFranq: getCookie(CONFIG.cookiesDict.b2wFranq),
    b2wOpn: getCookie(CONFIG.cookiesDict.b2wOpn),
    userNick: getCookie(CONFIG.cookiesDict.userNick),
    facebookImgUrl: getCookie(CONFIG.cookiesDict.facebookImgUrl),
    persistentCep: getCookie(CONFIG.cookiesDict.persistentCep),
    customerId: getCookie(CONFIG.cookiesDict.customerId),
    c_prime: getCookie(CONFIG.cookiesDict.c_prime),
    newBasketTestAB: getCookie('newBasketTestAB') as Experiment['variant'],
    telemarketerToken: getCookie(CONFIG.cookiesDict.telemarketerToken),
    fingerprint: getCookie(CONFIG.cookiesDict.fingerprint),
    salesChannel: getCookie(CONFIG.cookiesDict.salesChannel),
    catalogTestAB: getCookie(CONFIG.cookiesDict.catalogTestAB),
    warehouseId: getCookie(CONFIG.cookiesDict.warehouseId),
    brandFromFront: CONFIG.brand as Brand,
    riskifiedDeviceId: getSafeRiskifiedDeviceId,
  };
};
