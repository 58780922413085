import CONFIG from 'generated-config';

const APP_VERSION = CONFIG.appVersion;

type ScreenWithCachedComponents = Session['screen'];

const COMPONENTS_WITH_NO_CACHE = ['store-conflict-modal'];

const SCREENS_WITH_CACHED_COMPONENTS: ScreenWithCachedComponents[] = ['BASKET'];

const getScreenCachedComponentKey = (screen: ScreenWithCachedComponents) => `${screen}_components`;

export const setScreenCacheComponents = (
  screen: ScreenWithCachedComponents,
  components: BaseComponent[],
) => {
  const cacheableComponents = components.filter(v => !COMPONENTS_WITH_NO_CACHE.includes(v.rel));
  localStorage.setItem('APP_VERSION', APP_VERSION);

  if (SCREENS_WITH_CACHED_COMPONENTS.includes(screen)) {
    localStorage.setItem(getScreenCachedComponentKey(screen), JSON.stringify(cacheableComponents));
  }
};

export const getScreenCacheComponents = (screen: ScreenWithCachedComponents) =>
  JSON.parse(localStorage.getItem(getScreenCachedComponentKey(screen)) ?? '[]');

export const clearCachedComponents = () => {
  SCREENS_WITH_CACHED_COMPONENTS.forEach(screen =>
    localStorage.removeItem(getScreenCachedComponentKey(screen)),
  );
};
