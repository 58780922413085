import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../assets/icons/icon-arrow-down.svg';

const DebugOptionItemComponent: React.FC<{
  changeDebugModeHandler: () => void;
  optionLabel: string;
  errorCount?: number;
}> = ({ changeDebugModeHandler, optionLabel, errorCount }) => {
  return (
    <DebugOptionItemWrapper onClick={changeDebugModeHandler}>
      {Boolean(errorCount) && <ErrorCount>{errorCount}</ErrorCount>}
      <DebugOptionItem className="--ignore-case">{optionLabel}</DebugOptionItem>
      <ArrowDown className="optionArrow" />
    </DebugOptionItemWrapper>
  );
};

const DebugOptionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 10px 0px;
  cursor: pointer;

  > .optionArrow {
    width: 10px;
    height: auto;
    transition: transform 0.5s ease;
    transform: rotate(270deg);
    margin-left: auto;
  }
`;

const DebugOptionItem = styled.li`
  list-style-type: none;
  font-size: 16px;
`;

const ErrorCount = styled.span`
  display: flex;
  align-items: center;
  background-color: red;
  color: white;
  font-size: 0.5rem;
  border-radius: 50%;
  padding: 5px;
  height: 15px;
  margin-right: 5px;
`;

export default DebugOptionItemComponent;
