import { useCallback, useState } from 'react';

type UseStackType<StackType> = {
  add: (stackData: StackType) => void;
  updateTop: (stackData: StackType) => void;
  remove: () => void;
  top: StackType | undefined;
  clear: () => void;
};

export const useStack = <StackType>(stackData?: StackType): UseStackType<StackType> => {
  const [stack, setStack] = useState<StackType[]>(() => (stackData ? [stackData] : []));

  const remove = useCallback<UseStackType<StackType>['remove']>(() => {
    setStack(prevStack => {
      if (prevStack.length > 1) {
        const stackCopy = [...prevStack];
        stackCopy.pop();
        return stackCopy;
      }
      return [];
    });
  }, []);

  const add = useCallback<UseStackType<StackType>['add']>(data => {
    setStack(prevStack => {
      const stackCopy = [...prevStack];
      stackCopy.push(data);
      return stackCopy;
    });
  }, []);

  const updateTop = useCallback<UseStackType<StackType>['updateTop']>(
    data => {
      remove();
      add(data);
    },
    [add, remove],
  );

  const clear = () => {
    setStack([]);
  };

  return {
    add,
    remove,
    top: stack.slice(-1)[0],
    updateTop,
    clear,
  };
};
