import { forwardRef } from 'react';
import styled from 'styled-components';
import Loader from './loader.component';

type ButtonComponentProps = {
  isLoading?: boolean;
  buttonType?: 'primary' | 'secondary';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const BrandButton = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  ({ isLoading = false, buttonType = 'primary', children, ...attrs }, ref) => {
    return (
      <BrandButtonStyle {...attrs} buttonType={buttonType} isLoading={isLoading} ref={ref}>
        {isLoading && (
          <Loader borderColor={buttonType === 'primary' ? '#fff' : ''} height={16} width={16} />
        )}
        {children}
      </BrandButtonStyle>
    );
  },
);

const Button = styled.button`
  text-decoration: none;
  touch-action: manipulation;
  white-space: nowrap;
  border-radius: 3px;
  transition: all 100ms ease-in;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;
  display: flex;
  position: relative;
  flex: none;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0.5rem 1.5rem;
  line-height: 1.5;

  &:disabled {
    cursor: not-allowed;
    filter: opacity(70%);
  }

  > .loader {
    position: absolute;
  }
`;

export const BrandButtonStyle = styled(Button).attrs<{ isLoading?: boolean }>(
  ({ isLoading, ...attrs }) => ({
    ...attrs,
    disabled: isLoading || attrs.disabled,
  }),
)<{ isLoading?: boolean; buttonType: string }>`
  --brandColor: ${({ buttonType, theme }) =>
    buttonType === 'primary' ? theme.ctaColor : theme.brandColor};
  --backgroundColor: ${({ buttonType, theme }) =>
    buttonType === 'primary' ? theme.ctaColor : 'white'};
  --textColor: ${({ theme, buttonType }) =>
    buttonType === 'primary' ? 'white' : theme.brandColor};
  background-color: var(--backgroundColor);
  color: ${({ isLoading }) => (isLoading ? 'transparent' : 'var(--textColor)')};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--brandColor);
  font-size: ${({ buttonType }) => (buttonType === 'primary' ? '18px' : '16px')};
  &:hover {
    filter: opacity(90%);
  }
`;

export default BrandButton;
