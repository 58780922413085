export type ScreenPathName = 'obrigado' | 'pagamento' | 'carrinho';

export const getScreenFromUrlPath = (): ScreenPathName => {
  const pathName = window?.location?.pathname ?? '';
  const screen = pathName
    .split('/')
    .filter(v => v !== '')
    .pop() as ScreenPathName;

  return Boolean(screen) ? screen : 'pagamento';
};
