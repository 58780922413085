import { createGlobalStyle } from 'styled-components';
import ModalStyles from './modal/styles/modal.styles';

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  * {
    font-family: ${({ theme }) => theme.brandFont};
  }
   *:not(input,textarea) {
    text-transform: ${({ theme }) => (theme.brand === 'acom' ? 'lowercase' : 'inherit')}
  }
  .--ignore-case {
    text-transform: initial;
  }

  .center-modal {
    margin: auto;
    max-width:80%;

    @media (max-width: 768px) {
      width: 95%;
      max-width:95%;
    }
  }

.multi-sku-recommendation-modal {
  margin: auto;
  max-width: 410px;
  @media (max-width: 768px) {
      width: 95%;
      max-width:95%;
    }
}

  .summary-modal {
    padding: 2.5rem 2.5rem 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    max-width: min(1200px, 95%);
    @media (max-width: 768px) {
      padding: .5rem;
      width: 100%;
      border-radius: 0;
    }
  }

  .recipient-form-modal {
    max-width: 500px;
    padding: 2.5rem 2.5rem 3rem;
    @media (max-width: 768px) {
      padding: 2rem;
      padding-bottom: 3rem;
    }
  }

  /* estilos de payment */

  .payment-button {
    width: 100%;
    height: 60px;
  }

    .payment {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    animation: fadein 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  @keyframes fadein {
    0% {
      transform: translateZ(80px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  .payment-header {
    display: flex;
    align-items: center;
    height: 90px;
    background-color: darkslateblue;
  }

  .payment-header__content {
    padding: 15px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }

  .payment-SummaryAndDeliveryAddress__wrapper,
  .payment-orderFreightList,
  .payment-orderTicket,
  .payment-orderPaymentMethods,
  .payment-orderGiftVoucher,
  .payment-storeDelivery,
  .payment-noteInvoice,
  .payment-scheduledPickup {
    padding: 20px 15px;
    border-top: 1px solid #e3e3e3;
    &:last-child {
    margin-bottom: 0;
    }
    @media (max-width: 320px) {
      padding: 20px 5px;
    }
  }

  .payment-SummaryAndDeliveryAddress__wrapper {
  border-top: none;
}

.payment-checkoutSummary,
.payment-deliveryAddress {
  min-height: 100px;
  margin-bottom: 0;
  flex-grow: 1;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    max-width: 50%;
  }
}

.payment-deliveryAddress {
  margin-bottom: 0;
}

.payment-footer {
  padding: 15px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  border-top: #e3e3e3 1px solid;
  line-height: 1.4;
  font-size: 0.8rem;
  text-align: center;
  color: #666;
  > p {
    margin-bottom: 0.5rem;
  }
  > img{
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) {
  .payment-SummaryAndDeliveryAddress__wrapper {
    display: flex;
  }
  .payment-SummaryAndDeliveryAddress__separation {
    border: none;
    width: initial;
    width: 50%;
    margin-bottom: 0;
    &:last-child {
      margin-left: 20px;
    }
  }
  .payment-deliveryAddress {
    margin-bottom: 0px;
    padding-right: 15px;
  }
  .payment-checkoutSummary {
    border-left: 1px solid #e3e3e3;
    padding-left: 1rem;
  }
}

.payment-checkoutSummary-wrapper {
  background-color: #f8f8f8;
  padding: 1rem;
}

.bounce-animation {
  animation: bounce 1.2s infinite ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

  .payment_menu_icon {
    width: 45px;
    @media (max-width: 768px) {
      height: 17px;
    }
  }

  #bank-slip .how-to .text {
    @media (max-width: 768px) {
      display: flex;
      line-height: 14px;
      flex-direction: column;
      justify-content: center;
    }
  }

  #bank-slip .how-to svg {
    max-width: 45px;
    max-height: 45px;
    margin-right: 5px;
    vertical-align: middle;
    flex-shrink: 0;
    @media (max-width: 768px) {
      width: 60px;
    }
  }

  #bank-slip .safe-buy svg,
  #corporate .safe-buy svg {
    max-width: 12px;
    max-height: 12px;
    margin-left: 5px;
    vertical-align: middle;
  }

  #bank-slip .important,
  #corporate .important {
    color: #666;
    margin-left: 15px;
    margin-top: 10px;
  }

  #bank-slip .important h3,
  #corporate .important h3 {
    font-size: 14px;
    margin-bottom: 7px;
  }

  #bank-slip .important ul {
    margin-left: 15px;
  }

  #bank-slip .important ul li {
    font-size: 12px;
    margin-bottom: 7px;
  }

  #bank-slip .confirmation,
  #corporate .confirmation {
    color: #666;
    margin: 0.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-self: end;
    justify-content: space-around;
  }
  #bank-slip .confirmation > span,
  #corporate .confirmation > span,
  #bank-slip .confirmation > button,
  #corporate .confirmation > button {
    flex: 1;
    font-size: 18px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  #bank-slip .confirmation > span,
  #corporate .confirmation > span {
    font-size: 16px;
  }

  #bank-slip .confirmation .values,
  #corporate .confirmation .values {
    text-align: right;
    @media (max-width: 768px) {
      font-weight: 900;
    }
  }

  #bank-slip .confirmation .values .promotion,
  #corporate .confirmation .values .promotion {
    width: 100%;
    display: block;
    color: #66ce02;
    font-size: 12px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  #bank-slip .confirmation .safe-buy,
  #corporate .confirmation .safe-buy {
    font-size: 12px;
    padding-top: 30px;
    text-align: right;
    @media (max-width: 768px) {
      padding-top: 15px;
    }
  }

  .bounce-animation {
    animation: bounce 1.2s infinite ease-in-out;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .schedule-modal {
    padding-right: 20px;
  }
  
  .new-modal.unavailable-modal{
    padding: 23px;
    align-self: center;
    width:282px;
    @media (max-width:768px){
      width:282px;
      align-self:center;
      padding: 23px;
    }
  }

  .new-modal.unavailable-change-adress-modal {
    padding: 32px;
    width: 392px;
    max-height: 545px;
    align-self: center;
    overflow-y: overlay;
    @media (max-width:768px){
      padding: 16px;
      width: 100%;
      max-height: 100vh;
      min-height: 100vh;
    }
  }

  .payment-button {
    width: 100%;
  }

  .select-store-button {
    width: 100%;
  }

  .--money {
    text-transform: none;
  }

  .savedCreditCardIcon {
    margin-left: 20px;
  }

  .newCardPlusIcon {
    margin-right: 20px;
    margin-left: 5px;
  }

  .smallSecurityCodeField.smallSecurityCodeField {
    width: 30%;

    @media (max-width: 768px) {
      width: 45%;
    }
  }


  /* https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${ModalStyles}
  body{

 

  *{
    scrollbar-width: 5px;
    &::-webkit-scrollbar {
    background-color: transparent;
    border: none;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 5px;
    width: 0.5rem;
  }
  }
}

  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
  
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #666;
  }

   *:not(input,textarea) {
    text-transform: ${({ theme }) => (theme.brand === 'acom' ? 'lowercase' : 'inherit')}
  }
  .--ignore-case {
    text-transform: initial;
  }
  div.service-list-modal{
    padding:0;
    > div:first-child {
    padding: 1rem;
  }
  }
`;

export default GlobalStyles;
