import styled from 'styled-components';
import DebugHistory from './components/DebugHistory.component';
import DebugConfigList from './components/DebugConfigList.component';
import { BffDebugPayload, DebugMode, RequestDebugPayload, useDebug } from '../debug/Debug.context';
import DebugOptionItemComponent from './components/DebugOptionItem.component';
import downloadIcon from '../assets/icons/download.png';
import CONFIG from '../generated-config';
import { BrandButton } from 'components/brand-button.component';

const LOCALE = CONFIG.locale.debugMode;

const DebugContainer = () => {
  const { debugMode, changeDebugMode, logs } = useDebug();
  const bffLogs = logs.filter((v): v is BffDebugPayload => v.type === 'BFF_LOG');
  const requestLogs = logs.filter((v): v is RequestDebugPayload => v.type === 'REQUEST_LOG');

  const hasRequestLog = requestLogs.length > 0;
  const debugModeOptions: { option: Exclude<DebugMode, 'MODE_LIST'>; errorCount?: number }[] = [
    { option: 'CONFIG_LIST' },
    {
      option: 'ACTION_HISTORY',
      errorCount: bffLogs.filter(v => v.status === 'error').length,
    },
    { option: 'REQUEST_HISTORY', errorCount: requestLogs.filter(v => v.status === 'error').length },
  ];

  const debugModeMenuOptionList = !hasRequestLog
    ? debugModeOptions.filter(v => v.option !== 'REQUEST_HISTORY')
    : debugModeOptions;

  switch (debugMode) {
    case 'ACTION_HISTORY':
    case 'REQUEST_HISTORY':
      return (
        <DebugHistory
          bffLogs={bffLogs}
          requestLogs={requestLogs}
          debugMode={debugMode}
          onBackClickHandler={() => changeDebugMode('MODE_LIST')}
        />
      );
    case 'CONFIG_LIST':
      return <DebugConfigList />;
    case 'MODE_LIST':
    default:
      return (
        <>
          <DebugModalTitle className="--ignore-case">Debug</DebugModalTitle>
          <ul>
            {debugModeMenuOptionList.map((mode, index) => (
              <DebugOptionItemComponent
                key={`${index}_${mode}`}
                changeDebugModeHandler={() => changeDebugMode(mode.option)}
                optionLabel={LOCALE[mode.option].label}
                errorCount={mode.errorCount}
              />
            ))}
          </ul>
          <BrandButton style={{ margin: '30px auto 0px auto' }}>
            <DownloadFullLogLink
              href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(logs))}`}
              download={`CHECKOUT_FULL_DEBUG.json`}
              className="--ignore-case"
            >
              <DownloadIcon src={downloadIcon} />
              Baixar Log Completo
            </DownloadFullLogLink>
          </BrandButton>
        </>
      );
  }
};

const DebugModalTitle = styled.h1`
  text-align: center;
  padding-bottom: 20px;
  font-size: 32px;
`;

const DownloadFullLogLink = styled.a`
  font-size: 1rem;
  text-decoration: none;
  color: white;
`;

const DownloadIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  color: white;
`;

export default DebugContainer;
