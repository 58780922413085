import { useContext } from 'react';
import { FloatNotificationContext } from './floatNotification.context';
import FloatNotificationPortal from './components/floatNotification-portal.component';

const FloatNotificationContainer = () => {
  const { state, closeFloatNotification, onAnimationEnd } = useContext(FloatNotificationContext);
  const { currentStatus, component, options } = state;

  const DEFAULT_ANIMATION_CLASSES = {
    CLOSED: '--closed',
    OPENING: '--opening',
    OPEN: '--open',
    CLOSING: '--closing',
  };

  const defaultHandler = () => {};

  const defaultFloatNotificationProps = {
    closeFloatNotificationHandler: closeFloatNotification || defaultHandler,
    floatNotificationDataCy: options.floatNotificationDataCy,
    enableCloseOnEsc: options.enableCloseOnEsc,
    animationClassName: DEFAULT_ANIMATION_CLASSES[currentStatus],
    wrapperClassName: options.wrapperClassName,
    enableClose: options.enableClose,
    timeout: options.timeout,
    type: options.type,
  };

  return {
    CLOSED: null,
    OPENING: (
      <FloatNotificationPortal
        {...defaultFloatNotificationProps}
        onStageAnimationEnd={onAnimationEnd}
      >
        {component}
      </FloatNotificationPortal>
    ),
    OPEN: (
      <FloatNotificationPortal {...defaultFloatNotificationProps}>
        {component}
      </FloatNotificationPortal>
    ),
    CLOSING: (
      <FloatNotificationPortal
        {...defaultFloatNotificationProps}
        onBackdropAnimationEnd={onAnimationEnd}
      >
        {component}
      </FloatNotificationPortal>
    ),
  }[currentStatus || 'CLOSED'];
};

export default FloatNotificationContainer;
