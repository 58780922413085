import { QueryConfig, useQuery } from 'react-query';

const useBffStandaloneComponent = <C extends BaseComponent, P>(
  screen: SessionScreen,
  action: StandaloneAction,
  rel: C['rel'],
  extractFn: (component?: C) => P,
  queryConfig: QueryConfig<Session> = {
    enabled: false,
  },
): [P, { isFetching: boolean; refetch: () => Promise<Session | undefined> }] => {
  const { data: sessionMeta } = useQuery<SessionMeta, ['SESSION_META', SessionScreen]>(
    ['SESSION_META', screen],
    { enabled: false },
  );

  const { data: screenData, isFetching, refetch } = useQuery<
    Session,
    ['BFF_STAND_ALONE', StandaloneAction, SessionMeta | undefined]
  >(['BFF_STAND_ALONE', action, sessionMeta], {
    ...queryConfig,
  });

  const components = screenData?.components ?? [];
  const component = components.find((c): c is C => c.rel === rel);

  return [extractFn(component), { isFetching, refetch }];
};

export default useBffStandaloneComponent;
