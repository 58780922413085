import { InitialLoaderProps } from '../initial-loader.component';
import { ReactComponent as Logo } from '../../../assets/icons/acom-box.svg';
import styled, { keyframes } from 'styled-components';
import { QUERY_STATUS } from '../../../services/bff/bff.service';

const AcomInitialLoaderComponent: React.FC<InitialLoaderProps> = ({
  queryStatus,
  setPaymentScreenStatus,
}) => {
  const queryFinished = queryStatus === QUERY_STATUS.SUCCESS || queryStatus === QUERY_STATUS.ERROR;
  return (
    <>
      <LogoContainer>
        <FirstBox className={queryFinished ? 'shouldGoOut' : ''} />
        <SecondBox
          className={queryFinished ? 'shouldGoOut' : ''}
          onAnimationEnd={() => {
            queryFinished && setPaymentScreenStatus(queryStatus);
          }}
        />
        <ThirdBox className={queryFinished ? 'shouldGoOut' : ''} />
      </LogoContainer>
      <LoadingText shouldShow={!queryFinished}>Estamos montando seu pagamento...</LoadingText>
    </>
  );
};

const bounceTop = keyframes`
  0% {
    transform: translateY(0px);
  }
  10.4% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
  }
  32% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  52% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  65.6% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  74.4% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  20%,
  44%,
  60%,
  69.6% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  80% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
`;
const slideInTop = keyframes`
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0);
  }
`;
const slideOutBlurredBottom = keyframes`
  0% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px) scaleY(2) scaleX(0.2);
    transform-origin: 50% 100%;
    filter: blur(40px);
    opacity: 0;
  }
`;
const fadeIn = keyframes`
  45% {
    opacity: 0
  }
  70% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
`;

const FirstBox = styled(Logo)`
  left: 0;
  position: absolute;
  animation: ${slideInTop} 0.5s 0.3s ease-in both, ${bounceTop} 1.5s 0.8s infinite;
  &.shouldGoOut {
    animation: ${slideOutBlurredBottom} 0.3s 0.05s forwards;
  }
  @media (max-width: 450px) {
    width: 100px;
  }
`;

const SecondBox = styled(Logo)`
  position: absolute;
  top: 70px;
  left: 50%;
  margin-left: -81px;
  z-index: 1;
  animation: ${slideInTop} 0.5s ease-in both, ${bounceTop} 1.5s 0.5s infinite;
  &.shouldGoOut {
    animation: ${slideOutBlurredBottom} 0.3s forwards;
  }
  @media (max-width: 450px) {
    width: 100px;
    top: 50px;
    margin-left: -50px;
  }
`;

const ThirdBox = styled(Logo)`
  position: absolute;
  right: 0;
  animation: ${slideInTop} 0.5s 0.6s ease-in both, ${bounceTop} 1.5s 1.1s infinite;
  &.shouldGoOut {
    animation: ${slideOutBlurredBottom} 0.3s 0.1s forwards;
  }
  @media (max-width: 450px) {
    width: 100px;
  }
`;

const LogoContainer = styled.div`
  position: relative;
  height: 200px;
  width: 390px;
  @media (max-width: 450px) {
    width: 245px;
  }
`;

const LoadingText = styled.p<{ shouldShow: boolean }>`
  opacity: 0;
  text-align: center;
  animation: ${fadeIn} 2.2s both;
  color: ${props => (props.shouldShow ? '#666' : 'transparent')};
  position: absolute;
  left: calc(50% - 133px);
  top: calc(50% + 140px);
`;

export default AcomInitialLoaderComponent;
