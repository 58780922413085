import ShopInitialLoader from './components/shop-initial-loader.component';
import CONFIG from '../../generated-config';
import SubaInitialLoader from './components/suba-initial-loader.component';
import AcomInitialLoader from './components/acom-initial-loader.component';
import Loader from 'components/loader.component';

export type InitialLoaderProps = {
  queryStatus: QueryStatus;
  setPaymentScreenStatus: (status: QueryStatus) => void;
  screen: ScreenNames;
};

const InitialLoaderComponent: React.FC<InitialLoaderProps> = props => {
  if (props.screen === 'BASKET') {
    if (props.queryStatus === 'success' || props.queryStatus === 'error') {
      props.setPaymentScreenStatus(props.queryStatus);
    }

    return <Loader width={35} height={35} />;
  }

  const BRAND_LOADER_MAP: { [key in string]: JSX.Element } = {
    acom: <AcomInitialLoader {...props} />,
    aemp: <AcomInitialLoader {...props} />,
    prtl: <AcomInitialLoader {...props} />,
    suba: <SubaInitialLoader {...props} />,
    shop: <ShopInitialLoader {...props} />,
  };

  return BRAND_LOADER_MAP[CONFIG.brand];
};

export default InitialLoaderComponent;
