import React, { useContext, createContext, useState } from 'react';
import { getCookie, setCookie } from 'services/cookie/cookie.service';

type FreightState = FreightInfoComponentPayload & { storeApiId?: string; zipCode?: string };

type FreightContext = {
  state: FreightState;
  updateState: (
    stateFields: Partial<FreightState>,
    unavailableModal?: UnavailableModalComponent,
  ) => void;
  resetState: () => void;
  unavailableModal?: UnavailableModalComponent;
};

const INITIAL_STATE: FreightState = {
  freightOptions: [],
  productList: { warnings: [] },
  summary: {},
  warnings: [],
  storeApiId: '',
  zipCode: getCookie('persistentCep'),
};

export const FreightContext = createContext<FreightContext>({
  state: INITIAL_STATE,
  updateState: () => {},
  resetState: () => {},
});

FreightContext.displayName = 'FreightContext';

export const FreightProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const [unavailableModal, setUseUnavailableModal] = useState<
    UnavailableModalComponent | undefined
  >();

  const updateState = (
    stateFields: Partial<FreightState>,
    unavailableModal?: UnavailableModalComponent,
  ) => {
    if ('selectedOptionId' in stateFields) {
      setCookie('preferredFreightOptionId', stateFields?.selectedOptionId ?? 'CONVENTIONAL');
    }
    setUseUnavailableModal(unavailableModal);
    setState(state => ({ ...state, ...stateFields, unavailableModal }));
  };

  const resetState = () => {
    setState(INITIAL_STATE);
  };

  return (
    <FreightContext.Provider value={{ unavailableModal, state, updateState, resetState }}>
      {children}
    </FreightContext.Provider>
  );
};

export const useFreight = () => {
  const { state, updateState, resetState } = useContext(FreightContext);
  return { ...state, updateState, resetState };
};
export const useUnavailableDelivery = () => {
  const { unavailableModal } = useContext(FreightContext);
  return { unavailableModal };
};
