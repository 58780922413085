import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';
import CopyIconComponent from './CopyIcon.component';

type DebugActionRequestInfoComponentProps = {
  requests: Array<MetaSentRequest | MetaFailedRequest>;
};

const RequestInfoComponent: React.FC<{ index: number; v: MetaSentRequest | MetaFailedRequest }> = ({
  index,
  v,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (ref.current) {
      const clipboard = new Clipboard(ref.current);
      clipboard.on('success', () => {});
    }
  }, [ref]);

  return (
    <Fragment key={`debug-action-info__${index}`}>
      <RequestsWrapper>
        {v.status === 'error' && (
          <RequestErrorMessage className="--ignore-case">
            Status: {v?.response?.httpStatusCode ?? ''}
            <br />
            Mensagem de erro: {v?.response?.message ?? ''}
          </RequestErrorMessage>
        )}
        <p className="--ignore-case">Method: {v.method}</p>
        <RequestInfoWrapper>
          {v.method === 'GET' ? (
            <span className="--ignore-case">
              Url:{' '}
              {
                <a
                  href={v.fullUrl.includes('restql') ? `${v.fullUrl}&_debug=true` : v.fullUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--ignore-case"
                >
                  {v.fullUrl}
                </a>
              }
            </span>
          ) : (
            <span className="--ignore-case">Url: {v.fullUrl}</span>
          )}
          <CopyIconComponent clipboardText={JSON.stringify(v.fullUrl)} />
        </RequestInfoWrapper>
        {v.body && (
          <RequestInfoWrapper>
            <span className="--ignore-case">Body: {JSON.stringify(v.body, null, 2)}</span>
            <CopyIconComponent clipboardText={JSON.stringify(v.body)} />
          </RequestInfoWrapper>
        )}
        <p className="--ignore-case">Headers: {JSON.stringify(v.headers ?? {})}</p>
        <CopyCurlButton ref={ref} data-clipboard-text={v.curl ?? ''}>
          Copiar cURL
        </CopyCurlButton>
      </RequestsWrapper>
    </Fragment>
  );
};

const DebugActionRequestInfoComponent: React.FC<DebugActionRequestInfoComponentProps> = ({
  requests,
}) => {
  if (requests.length === 0) return null;

  return (
    <>
      {requests.map((v, index) => {
        return <RequestInfoComponent key={`debug-action-info__${index}`} index={index} v={v} />;
      })}
    </>
  );
};

const RequestsWrapper = styled.div`
  overflow-wrap: anywhere;
  border: 1px solid black;
  margin-bottom: 15px;
  padding: 10px;
`;

const RequestInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RequestErrorMessage = styled.p`
  color: red;
  margin: 0px 10px 20px 0px;
`;

const CopyCurlButton = styled.button`
  background-color: ${({ theme }) => theme.brandColor};
  border-radius: 5px;
  padding: 10px;
  margin: 15px auto 0px auto;
  border: 0px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  text-align: center;
`;

export default DebugActionRequestInfoComponent;
