import ReactDOM from 'react-dom';
import BackdropComponent from './backdrop.component';
import closeIcon from '../../assets/icons/modal-close-icon.svg';

type ModalPortal = {
  closeModalHandler: () => void;
  animationClassName: string;
  onBackdropAnimationEnd?: () => void;
  onStageAnimationEnd?: () => void;
  wrapperClassName?: string;
  hideCloseIcon?: boolean;
  enableBackDropClick?: boolean;
  enableCloseOnEsc?: boolean;
  enableClose?: boolean;
  hasHeader?: boolean;
  modalDataCy?: string;
};

const ModalPortal: React.FC<ModalPortal> = ({
  animationClassName,
  onBackdropAnimationEnd,
  onStageAnimationEnd,
  wrapperClassName = '',
  closeModalHandler,
  hideCloseIcon,
  children,
  enableBackDropClick,
  enableCloseOnEsc,
  enableClose,
  hasHeader,
  modalDataCy,
}) => {
  const modalRootNode = document.getElementById('modal-root');

  return (
    modalRootNode &&
    ReactDOM.createPortal(
      <BackdropComponent
        className={animationClassName}
        closeModalHandler={closeModalHandler}
        onAnimationEnd={onBackdropAnimationEnd}
        enableBackDropClick={enableBackDropClick}
        enableCloseOnEsc={enableCloseOnEsc}
        enableClose={enableClose}
      >
        <div
          role="dialog"
          className={`new-modal ${wrapperClassName}`}
          id="modal"
          onClick={e => e.stopPropagation()}
          onAnimationEnd={onStageAnimationEnd}
          style={hasHeader ? { paddingTop: '85px' } : undefined}
          data-cy={modalDataCy}
        >
          {enableClose && !hideCloseIcon && (
            <img className="close-icon" alt="" onClick={closeModalHandler} src={closeIcon} />
          )}
          {children}
        </div>
      </BackdropComponent>,
      modalRootNode,
    )
  );
};

export default ModalPortal;
