export const getExperimentFromQueryParam = (): Experiment[] => {
  const searchParams = new URLSearchParams(window.location.search);

  const expirementIdsFromUrl = searchParams.get('EXPERIMENT_IDS') ?? '';
  const sanatizedExpirementIds = sanatizeParamListFromUrl(expirementIdsFromUrl);
  const expirementVariants = searchParams.get('EXPERIMENT_VARIANTS') ?? '';
  const sanatizedExperimentVariants = sanatizeParamListFromUrl(expirementVariants) as Array<
    Experiment['variant']
  >;

  if (expirementIdsFromUrl.length === 0) {
    return [];
  }

  return sanatizedExpirementIds.map((v, i) => ({
    experimentId: v,
    variant: sanatizedExperimentVariants[i],
    weight: sanatizedExperimentVariants[i],
  }));
};

const sanatizeParamListFromUrl = (params: string) => {
  return params.replace(/" "/g, '').split(',');
};
