import styled, { keyframes, css } from 'styled-components';
import { QUERY_STATUS } from '../../../services/bff/bff.service';
import { ReactComponent as Logo } from '../../../assets/build/logo.svg';
import { InitialLoaderProps } from '../initial-loader.component';

const ShopInitialLoaderComponent: React.FC<InitialLoaderProps> = ({
  queryStatus,
  setPaymentScreenStatus,
}) => {
  const queryFinished = queryStatus === QUERY_STATUS.SUCCESS || queryStatus === QUERY_STATUS.ERROR;

  return (
    <>
      <Loader
        slideOut={queryFinished}
        onAnimationEnd={() => queryFinished && setPaymentScreenStatus(queryStatus)}
      >
        <GradientBackground />
        <AnimatedLogo />
      </Loader>
      <LoadingText shouldShow={!queryFinished}>Estamos montando seu pagamento...</LoadingText>
    </>
  );
};

const bounceInLeft = keyframes`
  0% {
    transform: translateX(-600px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateX(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateX(-68px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateX(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateX(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
`;

const fadeIn = keyframes`
  45% {
    opacity: 0
  }
  70% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
`;

const moveBackground = keyframes`
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 200px;
  }
`;

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px);
    opacity: 0;
  }
`;

const GradientBackground = styled.div`
  left: calc(50% - 52px);
  top: 27px;
  width: 100px;
  height: 100px;
  background: linear-gradient(
    315deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 5%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.2) 45%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 55%,
    rgba(255, 255, 255, 0) 75%,
    rgba(255, 255, 255, 0.2) 95%,
    rgba(255, 255, 255, 0.2) 100%
  );
  z-index: 2;
  position: absolute;
  animation: ${moveBackground} 1.5s linear infinite;
`;

const Loader = styled.div<{ slideOut: boolean }>`
  text-align: center;
  position: relative;
  animation: ${props =>
    props.slideOut
      ? css`
          ${slideOut} 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both
        `
      : css`
          ${bounceInLeft} 1.1s both
        `};
`;

const AnimatedLogo = styled(Logo)`
  animation: ${pulsate} 1.5s 1.1s ease-in-out infinite both;
`;

const LoadingText = styled.p<{ shouldShow: boolean }>`
  opacity: 0;
  text-align: center;
  animation: ${fadeIn} 2.2s both;
  color: ${props => (props.shouldShow ? '#999' : 'transparent')};
`;

export default ShopInitialLoaderComponent;
