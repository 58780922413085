import axios, { AxiosRequestConfig } from 'axios';
import getConfig from '../../helpers/getConfig/getConfig.helper';
import Optional from 'optional-js';
import { queryCache } from 'react-query';
import { getCookie } from '../cookie/cookie.service';
import CONFIG from '../../generated-config';
import {
  getScreenCacheComponents,
  setScreenCacheComponents,
} from 'services/cache-components/cache-components.service';

type RestoreSessionRequest = {
  restoreSessionUrl: string;
  meta: SessionMeta;
};

export type SessionActionRequest = {
  meta: SessionMeta;
  action: { type: Action['type']; payload: Action['payload'] };
  debugMode?: boolean;
};

export type SessionStandAloneRequest = {
  meta: SessionMeta;
  action: { type: StandaloneAction['type']; payload: StandaloneAction['payload'] };
  debugMode?: boolean;
};

type StandAloneActionRequest = {
  meta: SessionMeta;
  action: { type: StandaloneAction['type']; payload: StandaloneAction['payload'] };
  debugMode?: boolean;
};

export const QUERY_STATUS: QUERY_STATUS_MAP = {
  SUCCESS: 'success',
  LOADING: 'loading',
  ERROR: 'error',
  IDLE: 'idle',
};

type BFF_SCREENS = { [K in SessionScreen]: K };
export const BFF_SCREENS: BFF_SCREENS = {
  PAYMENT: 'PAYMENT',
  ADDRESS: 'ADDRESS',
  THANKS: 'THANKS',
  CONFIRMATION: 'CONFIRMATION',
  BASKET: 'BASKET',
  LOGIN: 'LOGIN',
  COMPLETE_SIGNUP: 'COMPLETE_SIGNUP',
};

type BFF_ACTIONS = { [K in ActionType]: K };
export const BFF_ACTIONS: BFF_ACTIONS = {
  CREATE_SESSION: 'CREATE_SESSION',
  APPLY_TICKET: 'APPLY_TICKET',
  CHANGE_ADDRESS: 'CHANGE_ADDRESS',
  CHANGE_FREIGHT: 'CHANGE_FREIGHT',
  GO_TO_CONFIRMATION: 'GO_TO_CONFIRMATION',
  GO_TO_CONFIRMATION_PCI: 'GO_TO_CONFIRMATION_PCI',
  PLACE_ORDER: 'PLACE_ORDER',
  PLACE_ORDER_WITH_STORE: 'PLACE_ORDER_WITH_STORE',
  REMOVE_COUPON: 'REMOVE_COUPON',
  REMOVE_VOUCHER: 'REMOVE_VOUCHER',
  APPLY_GIFT_VOUCHER: 'APPLY_GIFT_VOUCHER',
  CREATE_ADDRESS: 'CREATE_ADDRESS',
  GO_TO_PAYMENT: 'GO_TO_PAYMENT',
  CHANGE_WHATSAPP: 'CHANGE_WHATSAPP',
  GO_TO_THANKS: 'GO_TO_THANKS',
  CHANGE_PAYMENT_OPTION: 'CHANGE_PAYMENT_OPTION',
  CHANGE_RECIPIENT: 'CHANGE_RECIPIENT',
  CHANGE_PRODUCT_QUANTITY: 'CHANGE_PRODUCT_QUANTITY',
  SELECT_STORE: 'SELECT_STORE',
  REMOVE_ALL_VOUCHERS: 'REMOVE_ALL_VOUCHERS',
  UPDATE_ADDRESS_DOCUMENT: 'UPDATE_ADDRESS_DOCUMENT',
  CREATE_ADDRESS_AND_CHECKOUT: 'CREATE_ADDRESS_AND_CHECKOUT',
  UPDATE_NOTE_INVOICE: 'UPDATE_NOTE_INVOICE',
  PLACE_ORDER_PCI_FLOW: 'PLACE_ORDER_PCI_FLOW',
  CONFIRMATION_PLACE_ORDER: 'CONFIRMATION_PLACE_ORDER',
  CONFIRMATION_PLACE_ORDER_WITH_STORE: 'CONFIRMATION_PLACE_ORDER_WITH_STORE',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  ADD_SERVICE: 'ADD_SERVICE',
  REMOVE_SERVICE: 'REMOVE_SERVICE',
  RESOLVE_STORE_CONFLICT: 'RESOLVE_STORE_CONFLICT',
  SET_TELESALES_CONTEXT: 'SET_TELESALES_CONTEXT',
  CHANGE_ZIPCODE: 'CHANGE_ZIPCODE',
  ADD_PRODUCT_LINE: 'ADD_PRODUCT_LINE',
  GO_TO_BASKET: 'GO_TO_BASKET',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  SEARCH_ZIPCODE: 'SEARCH_ZIPCODE',
  GO_TO_ADDRESS: 'GO_TO_ADDRESS',
  RESTORE_SESSION: 'RESTORE_SESSION',
  CHANGE_FAVORITE: 'CHANGE_FAVORITE',
};

type BFF_STAND_ALONE_ACTIONS = { [K in StandaloneActionType]: K };
export const BFF_STAND_ALONE_ACTIONS: BFF_STAND_ALONE_ACTIONS = {
  OPEN_MODAL: 'OPEN_MODAL',
  GET_STORES_BY_ZIPCODE: 'GET_STORES_BY_ZIPCODE',
  GET_WALLET_PAYMENT: 'GET_WALLET_PAYMENT',
  GET_CREDIT_CARD_INFO: 'GET_CREDIT_CARD_INFO',
  GET_PIX_PAYMENT: 'GET_PIX_PAYMENT',
  GET_NEW_PIX_PAYMENT: 'GET_NEW_PIX_PAYMENT',
  GET_ORDER_COMPLETED: 'GET_ORDER_COMPLETED',
  GET_FREIGHT_INFO: 'GET_FREIGHT_INFO',
  GET_RECOMMENDATION: 'GET_RECOMMENDATION',
  GET_O2O_RECOMMENDATION: 'GET_O2O_RECOMMENDATION',
  GET_SEARCH_BAR_SUGGESTION: 'GET_SEARCH_BAR_SUGGESTION',
  GET_FAVORITES_INFO: 'GET_FAVORITES_INFO',
};

type BFF_DEFAULT_BEHAVIOURS = { [K in BffDefaultBehaviour]: K };
export const BFF_DEFAULT_BEHAVIOURS: BFF_DEFAULT_BEHAVIOURS = {
  HIDDEN: 'HIDDEN',
  VISIBLE: 'VISIBLE',
};

export const createSession = async (
  createSessionPayload: Screens['createPayload'],
): Promise<Session> => {
  const request = buildCreateSessionRequest(createSessionPayload);

  const { data } = await axios(request);

  return data;
};

export const buildCreateSessionRequest = (
  createPayload: Screens['createPayload'],
): AxiosRequestConfig => {
  const createSessionUrl = CONFIG.apis.bff.sessionCreate;
  const customerToken = getCookie(CONFIG.cookiesDict.customerApiToken);
  const urlParams = new URLSearchParams(window.location.search);
  const debugMode = urlParams.get('_debug') === 'true';

  return {
    method: 'POST',
    url: createSessionUrl,
    headers: {
      'access-token': customerToken,
    },
    params: { _debug: debugMode || undefined },
    data: createPayload,
  };
};

export async function restoreSession(meta: SessionMeta): Promise<Session> {
  const restoreSessionUrl = getConfig<string>(['apis', 'bff', 'sessionRestore'], '');
  const request = buildRestoreSessionRequest({ meta, restoreSessionUrl });

  const { data } = await axios(request);

  return data;
}

export const buildRestoreSessionRequest = ({
  restoreSessionUrl,
  meta,
}: RestoreSessionRequest): AxiosRequestConfig => {
  const customerToken = getCookie(CONFIG.cookiesDict.customerApiToken);

  return {
    url: restoreSessionUrl,
    method: 'POST',
    headers: {
      'access-token': customerToken,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    data: meta,
  };
};

export const sessionActionRequest = async ({
  meta,
  action,
}: SessionActionRequest): Promise<Session> => {
  const urlParams = new URLSearchParams(window.location.search);
  const debugMode = urlParams.get('_debug') === 'true';

  const request = buildSessionActionRequest({ meta, action, debugMode });

  const { data } = await axios(request);

  return data;
};

export const buildSessionActionRequest = ({
  meta,
  action,
  debugMode,
}: SessionActionRequest): AxiosRequestConfig => {
  const sessionActionUrl = getConfig<string>(['apis', 'bff', 'sessionAction'], '');

  const customerToken = getCookie(CONFIG.cookiesDict.customerApiToken);

  const { type: actionType, payload: actionPayload } = action;

  return {
    url: sessionActionUrl,
    method: 'POST',
    params: { _debug: debugMode || undefined },
    headers: {
      'access-token': customerToken,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    data: {
      type: actionType,
      meta,
      payload: actionPayload,
    },
  };
};

export const standAloneAction = async ({
  meta,
  action,
}: StandAloneActionRequest): Promise<Session> => {
  const urlParams = new URLSearchParams(window?.location?.search);
  const debugMode = urlParams.get('_debug') === 'true';

  const request = buildStandAloneAction({ meta, action, debugMode });

  const { data } = await axios(request);

  return data;
};

export const buildStandAloneAction = ({
  meta,
  action,
  debugMode,
}: StandAloneActionRequest): AxiosRequestConfig => {
  const standAloneActionUrl = getConfig<string>(['apis', 'bff', 'standaloneAction'], '');

  const customerToken = getCookie(CONFIG.cookiesDict.customerApiToken);

  const { type: actionType, payload: actionPayload } = action;

  return {
    url: standAloneActionUrl,
    method: 'POST',
    params: { _debug: debugMode || undefined },
    headers: {
      'access-token': customerToken,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    data: {
      type: actionType,
      meta,
      payload: actionPayload,
    },
  };
};

export const getBffComponent =
  <T extends BaseComponent>(rel: T['rel']) =>
  (payload: Session): Optional<T> => {
    const component = Optional.ofNullable(payload)
      .map(p => p.components)
      .orElse([])
      .find(c => c.rel === rel) as T;

    return Optional.ofNullable(component);
  };

export const isBffResponseWithoutComponents = (data?: Session): boolean =>
  Optional.ofNullable(data)
    .map(v => v.components)
    .map(c => c.length === 0)
    .orElse(true);

export const mergeBffResponseComponents = (
  actionResponse: Session,
  currentMeta?: SessionMeta,
): BaseComponent[] => {
  const currentScreenData = queryCache.getQueryData<Session>([
    'BFF_SCREEN',
    actionResponse.screen,
    currentMeta,
  ]);

  const responseComponents = actionResponse?.components ?? [];
  const responseComponentsRels = responseComponents.map(c => c.rel);

  const currentScreenComponents = currentScreenData?.components ?? [];

  return currentScreenComponents
    .filter(c => !responseComponentsRels.includes(c.rel))
    .concat(responseComponents);
};

export const setSessionCache = (sessionData: Session, currentMeta?: SessionMeta) => {
  const updatedComponents = mergeBffResponseComponents(sessionData, currentMeta);

  const updatedSessionData = {
    ...sessionData,
    components: updatedComponents,
  };

  setScreenCacheComponents(sessionData.screen, updatedComponents);

  queryCache.setQueryData(['BFF_SCREEN', sessionData.screen, sessionData.meta], updatedSessionData);
  queryCache.setQueryData(['BFF_METRICS', sessionData.screen], sessionData.metrics);
  queryCache.setQueryData(['SESSION_META', sessionData.screen], sessionData.meta);
};

export const setSessionCacheFromLocalStorage = (screen: Session['screen']) => {
  const components = getScreenCacheComponents(screen);
  const cacheAppVersion = localStorage.getItem('APP_VERSION') ?? '';

  if (Array.isArray(components) && components.length && cacheAppVersion === CONFIG.appVersion) {
    queryCache.setQueryData(['BFF_SCREEN', 'BASKET', undefined], {
      components,
    });
  }
};

export const setStandAloneSessionCache = (
  sessionData: Session,
  sessionMeta: SessionMeta,
  action: StandaloneAction,
) => {
  queryCache.setQueryData(['BFF_STAND_ALONE', action, sessionMeta], sessionData);
  queryCache.setQueryData(['SESSION_META', sessionData.screen], sessionData.meta);
};
