import { DebugPayload } from '../Debug.context';
import styled from 'styled-components';
import downloadIcon from '../../assets/icons/download.png';
import DebugActionRequestInfo from './DebugActionRequestInfo.component';
import CopyIconComponent from './CopyIcon.component';

const DebugItemInfo: React.FC<{ logEntry: DebugPayload }> = ({ logEntry }) => {
  switch (logEntry.type) {
    case 'BFF_LOG': {
      const sentRequests = logEntry.debug?.sentRequests ?? [];
      const failedRequests = logEntry.debug?.failedRequests ?? [];

      return (
        <>
          <LogEntryDownloadLink
            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(logEntry))}`}
            download={`${logEntry.action}_debug.json`}
            className="--ignore-case"
          >
            <DownloadIcon src={downloadIcon} />
            Baixar o log desta ação
          </LogEntryDownloadLink>

          <h3 className="--ignore-case" style={{ marginBottom: '15px' }}>
            Payload da ação
          </h3>
          <RequestsWrapper>
            <RequestInfoWrapper>
              <span className="--ignore-case">
                {JSON.stringify(logEntry.actionPayload, null, 2)}
              </span>
              <CopyIconComponent clipboardText={JSON.stringify(logEntry.actionPayload)} />
            </RequestInfoWrapper>
          </RequestsWrapper>

          {sentRequests.length > 0 && (
            <h3 className="--ignore-case" style={{ marginBottom: '15px' }}>
              Chamadas executadas:
            </h3>
          )}
          <DebugActionRequestInfo requests={sentRequests} />

          {failedRequests.length > 0 && (
            <h3 className="--ignore-case" style={{ marginBottom: '15px' }}>
              Chamadas com falha na execução:
            </h3>
          )}
          <DebugActionRequestInfo requests={failedRequests} />
        </>
      );
    }

    case 'REQUEST_LOG':
      return (
        <>
          <LogEntryDownloadLink
            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(logEntry))}`}
            download={`${logEntry.requestId}_debug.json`}
            className="--ignore-case"
          >
            <DownloadIcon src={downloadIcon} />
            Baixar o log desta chamada
          </LogEntryDownloadLink>

          <h3 className="--ignore-case" style={{ marginBottom: '15px' }}>
            Informações da chamada
          </h3>
          <RequestsWrapper>
            <RequestInfoWrapper>
              <span className="--ignore-case">Método: {JSON.stringify(logEntry.method)}</span>
            </RequestInfoWrapper>
            <RequestInfoWrapper>
              <span className="--ignore-case">Url: {JSON.stringify(logEntry.fullUrl)}</span>
              <CopyIconComponent clipboardText={JSON.stringify(logEntry.fullUrl)} />
            </RequestInfoWrapper>
            <RequestInfoWrapper>
              <span className="--ignore-case">Body: {JSON.stringify(logEntry.body, null, 2)}</span>
              <CopyIconComponent clipboardText={JSON.stringify(logEntry.body)} />
            </RequestInfoWrapper>
          </RequestsWrapper>
        </>
      );
  }
};

const LogEntryDownloadLink = styled.a`
  display: flex;
  text-decoration: none;
  color: black;
  margin-bottom: 20px;
`;

const RequestsWrapper = styled.div`
  overflow-wrap: anywhere;
  border: 1px solid black;
  margin-bottom: 15px;
  padding: 10px;
`;

const RequestInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DownloadIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;

export default DebugItemInfo;
